import { Link, useLocation } from "react-router-dom";
import NavBar from "../NavBar";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductDetails = () => {
  const { state: product } = useLocation();

  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const handleAddToCart = async (newProduct) => {
    try {
      setCart([...cart, newProduct]);
      console.log("Item added to cart:", newProduct);

      toast.success("Item has been added to the cart.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };


  const { img, title, description, category, brand, rating, price } = product;

  return (
    <div>
      <NavBar
        onAddToCart={() => setIsCartOpen(!isCartOpen)}
        cartCount={cart.length}
        cartItems={cart}
      />
      <ToastContainer />
      <section className="flex flex-col gap-16 py-10 bg-gray-100 mt-24">
        <div className="container mx-auto flex flex-col md:flex-row justify-around items-center w-[80%]">
          <div className="w-full md:w-96 flex justify-end">
            <img src={img} alt={title} className="w-full select-none" />
          </div>
          <div className="flex flex-col gap-6 mt-10">
            <p className="text-gray-500">
              <Link to="/Merchandise">Merchandise</Link>/{title}
            </p>
            <h2 className="text-4xl">{title.slice(0, 30)}</h2>
            <span className="font-semibold">
              Price: <span className="text-2xl">R{price}</span>
            </span>
            <span className="font-semibold">Brand: {brand}</span>
            <div className="flex flex-col gap-2">
              <h1 className="text-2xl">Key features</h1>
              <p className="text-gray-800">{description.slice(0, 35)}</p>
              <p className="text-gray-800">{description.slice(36, 70)}</p>
              <p className="text-gray-800">{description.slice(71, 100)}</p>
              <p className="text-gray-800">{description.slice(101, 130)}</p>
              <p className="text-gray-800">{description.slice(131, 170)}</p>
              <p className="text-gray-800">{description.slice(170, 201)}</p>
            </div>
            <h3 className="flex justify-between text-gray-700 text-lg">
              <span>Category: {category}</span>
              <span>
                Rating:{" "}
                <span className="text-rose-500 font-bold">
                  {rating.slice(0, 3)}
                </span>
                <span>{rating.slice(3)}</span>
              </span>
            </h3>
            <button
              onAddToCart={handleAddToCart}
              className="bg-sky-500 text-sky-50 px-2 py-1 mt-4"
            >
              Add to cart
            </button>
          </div>
        </div>
        <Link
          to="/Merchandise"
          className="text-xl py-1 text-center hover:text-cyan-500 duration-300 select-none"
        >
          &larr; Go back
        </Link>
      </section>
    </div>
  );
};

export default ProductDetails;