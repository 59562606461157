import {React, useState} from "react";
import AdminNavBar from "../Components/NavBar";
import SideNav from "../Components/Sidebar";
import Account from "./Account";
import Security from "./Security";

function Profile() {
  const [activeComponent, setActiveComponent] = useState("Account");

  const switchComponent = (componentName) => {
    setActiveComponent(componentName);
  };

  return (
    <>
    <AdminNavBar
    />
    <div className="flex flex-col md:flex-row">
      <SideNav switchComponent={switchComponent} />
      <div className="w-full">
        {activeComponent === "Account" && <Account />}
        {activeComponent === "Security" && <Security />}
        
      </div>
    </div>
  </>
    
  );
}

export default Profile;