import React from "react";
import NavBar from "../Components/NavBar";
import Music1 from "../../src/Assets/Images/2024/2024 April/Carameldeep-Groove-Oasis-EP.jpg";
import Track1 from "../../src/Assets/Images/Euphorea.jpg";
import image3 from "../../src/Assets/Images/Euphorea mix.jpg";
import Mix4 from "../../src/Assets/Images/2024/Mix 4.jpg"
const Songs = [
  {
    image: Music1,
    title: "Carameldeep-Groove-Oasis-EP",
    artist: "Carameldeep ft Euphorea",
    url: "https://www.traxsource.com/title/2243036/groove-oasis",
  },

  {
    title: "EuphoRea harmony-groove-voyage-vol-2",
    image: Mix4,
    artist: "Euphorea",
    url: "https://hearthis.at/reatletsoe/harmony-groove-voyage-vol-2-2/",
  },
  {
    title: "EuphoRea Soul Serenade Sessions Vol 2",
    image: Track1,
    artist: "Euphorea",
    url: "https://hearthis.at/reatletsoe/euphoreas-soul-serenade-session-vol2-1/",
  },
  {
    title: "SOUL SERENADE SESSION",
    image: image3,
    artist: "Euphorea",
    url: "https://hearthis.at/reatletsoe/soul-serenade-session/",
  },
  {
    title: "Hamony Groove Voyage Vol. 1",
    image: image3,
    artist: "Euphorea",
    url: "https://hearthis.at/reatletsoe/hamony-groove-voyage-vol-1/",
  },
];

const SpotifyPlaylist = ({ playlistId }) => {
  return (
    <div className="mb-8">
      <iframe
        title="Spotify Playlist"
        src={`https://open.spotify.com/embed/playlist/${playlistId}`}
        width="100%"
        height="400"
        frameBorder="0"
        allowtransparency="true"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  );
};

const Music = () => {
  const Album = ({ title, image, artist, url }) => {
    const handleGoToTrack = () => {
      if (url) {
        window.open(url, "_blank");
      }
    };

    return (
      <div class="bg-gray-900 shadow-lg rounded p-3">
        <div class="group relative">
          <img class="w-full md:w-72 block rounded" src={image} alt="" />
          <div class="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly">
            
            <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-heart"
                viewBox="0 0 16 16"
              >
                <path d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
              </svg>
            </button>

            <button
              onClick={handleGoToTrack}
              class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-play-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
              </svg>
            </button>

            <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-three-dots"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </button>
          </div>
        </div>

        <div class="p-5">
          <h3 class="text-white text-lg">{title}</h3>
          <p class="text-gray-400">{artist}</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <NavBar />
      <div>
        <div className="mt-20 grid min-h-screen p-16">
          <h2 className="text-2xl font-bold ">Euphorea's Music</h2>
          {/* <SpotifyPlaylist playlistId="37i9dQZF1E37wpPaxMkVtm?utm_source=generator" /> */}

          <div>
            <section class="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {Songs.map((song, index) => (
                <Album
                  key={index}
                  title={song.title}
                  image={song.image}
                  url={song.url}
                  artist={song.artist}
                />
              ))}

            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Music;
