import React, { useState } from "react";
// import Logo from "../Assets/Images/Euphorealogo.jpg";

const AdminNavBar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };



  return (
    <div>
      <header className=" fixed top-0 left-0 right-0 shadow-md py-4 px-4 sm:px-10 bg-white font-sans min-h-[70px] z-50">
        <div className="flex flex-wrap  items-center justify-between gap-4 ">

          <div className="max-lg:border-b max-lg:py-2 px-3">
            <a
              href="/Admin/Dashboard"
              className="hover:text-[#5e9daa] text-gray-600 font-bold block text-[20px]"
            >
             Euphorea Admin
            </a>
          </div>

          <div className="flex items-center max-sm:ml-auto lg:order-1 ">
            <button onClick={toggleMobileMenu} className="lg:hidden ml-7">
              <svg
                className={`w-6 h-6 ${isMobileMenuOpen ? "hidden" : "block"}`}
                fill="#000"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* Your existing closed menu icon path */}
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>

              <svg
                className={`w-6 h-6 ${isMobileMenuOpen ? "block" : "hidden"}`}
                fill="#000"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* Your existing open menu icon path */}
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div
            className={`items-center justify-between w-full ${isMobileMenuOpen ? "block" : "hidden"
              } lg:flex lg:w-auto lg:order-1`}
            id="mobile-menu-2"
          >
            <ul className="flex lg:space-x-4 max-lg:space-y-2 max-lg:block max-lg:w-full">
              <li className="max-lg:border-b max-lg:py-2 px-3">
                <a
                  href="/Admin/Dashboard"
                  className="hover:text-[#007bff] text-gray-600 font-bold block text-[15px]"
                >
                  Dashboard
                </a>
              </li>

              <li className="max-lg:border-b max-lg:py-2 px-3">
                <a
                  href="/Admin/Orders"
                  className="hover:text-[#007bff] text-gray-600 font-bold block text-[15px]"
                >
                  Orders
                </a>
              </li>
              
              <li className="max-lg:border-b max-lg:py-2 px-3">
                <a
                  href="/Admin/ProductsPage"
                  className="hover:text-[#007bff] text-gray-600 font-bold block text-[15px]"
                >
                  Products
                </a>
              </li>

              {/* <li className="max-lg:border-b max-lg:py-2 px-3">
                <a
                  href="/Music"
                  className="hover:text-[#007bff] text-gray-600 font-bold block text-[15px]"
                >
                  Music
                </a>
              </li> */}

              <li className="max-lg:border-b max-lg:py-2 px-3">
                <a
                  href="/Admin/BlogsPage"
                  className="hover:text-[#007bff] text-gray-600 font-bold block text-[15px]"
                >
                  Blogs
                </a>
              </li>

              <li className="max-lg:border-b max-lg:py-2 px-3">
                <a
                  href="/Admin/Settings"
                  className="hover:text-[#007bff] text-gray-600 font-bold block text-[15px]"
                >
                  Settings
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
};

export default AdminNavBar;
