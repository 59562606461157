import React, { useState } from "react";
import latestGig from "../Assets/Images/2024/2024 May/May 1.png"
import LatestGig2 from "../Assets/Images/2024/2024 March/lifestyle gig.jpg";
import LatestGig3 from "../Assets/Images/2024/2024 March/Latest gig 3.jpg";
import LatestGig4 from "../Assets/Images/2024/2024 March/Yfm.jpg"
import { XMarkIcon } from "@heroicons/react/24/outline";

import Event1 from "../Assets/Images/2024/2024 April/Event-1.jpg";
import Event2 from "../Assets/Images/2024/2024 April/Event-2.jpg";
import Event3 from "../Assets/Images/2024/2024 April/Event-3.jpg";

import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";



// const variants = {
//   initial: {
//     x: -1000,
//     y: 0,
//     opacity: 0
//   },
//   animate: {
//     x: -100,
//     opacity: 0,
//     Transition: {
//       x: -500,
//       duration: 2,
//       staggerChildren: 0.1,
//     }
//   }
// }

const UpcomingShow = ({ image, place, date, time, description, url }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleGoToTrack = () => {
    if (url) {
      window.open(url, "_blank");
    }
  };
  const hasUrl = !!url;

  return (
    // <div className="bg-white w-3/4 lg:ml-20 ml-14 shadow-lg rounded-lg overflow-hidden">
    //   <div className="relative">
    //     <motion.img whileTap={{ scale: 0.8, }}
    //       src={image}
    //       alt="Upcoming Show"
    //       className="w-full h-56 object-contain cursor-pointer"
    //       onClick={handleOpenModal}
    //     />
    //     {showModal && (
    //       <motion.div className="fixed inset-0 flex items-center justify-center z-50">
    //         <div className="absolute inset-0 bg-black opacity-50"></div>
    //         <div className="relative bg-white rounded-lg">
    //           <div className="flex justify-end">
    //             <button
    //               className="text-gray-500  "
    //               onClick={handleCloseModal}
    //             >
    //               <XMarkIcon color="white" className="h-6 w-6 mb-5 mt-5 bg-slate-500 rounded-full mr-2  hover:scale-125   " />
    //             </button>
    //           </div>
    //           <img
    //             src={image}
    //             alt="Upcoming Show"
    //             className="h-auto lg:max-w-2xl md:max-w-2xl px-5 rou"
    //           />
    //         </div>
    //       </motion.div>
    //     )}
    //   </div>

    //   <div className="p-4">
    //     <h3 className="text-xl font-bold mb-2 text-center">{place}</h3>
    //     <p className="text-slate-500 mb-2 text-lg text-center">
    //       {date} {time}
    //     </p>
    //     <p className="text-gray-700">{description}</p>
    //     {hasUrl && (
    //       <a
    //         href={url}
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="block text-center mt-4 text-blue-500 hover:underline"
    //       >
    //         Visit Link
    //       </a>
    //     )}
    //   </div>
    // </div>
    <div>
      <Card className="mt-6 w-96 shadow-md md:max-w-2xl transform hover:scale-105 duration-500 ease-in-out ">
        
          <img
            src={image}
            alt="cardimage"
            className=" h-96  w-full rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50  "
            onClick={handleOpenModal}
          />
        
        <CardBody>
          <Typography variant="h5" color="gray" className="mb-2">
            {place}
          </Typography>

          <Typography variant="h6" color="gray" className="mb-2">
            {date}
          </Typography>

          <Typography>
            {description}
          </Typography>
        </CardBody>
        <CardFooter className="pt-0" >
          {hasUrl && (

            <Button onClick={handleGoToTrack} >Visit Link</Button>
          )}

        </CardFooter>
      </Card>


      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative bg-white rounded-lg">
            <div className="flex justify-end">
              <button
                className="text-gray-500  "
                onClick={handleCloseModal}
              >
                <XMarkIcon color="black" className="h-6 w-6 mb-5 mt-5 bg-slate-500 rounded-full mr-2  hover:scale-125   " />
              </button>
            </div>
            <img
              src={image}
              alt="Upcoming Show"
              className="h-auto lg:max-w-2xl md:max-w-2xl px-5 "
            />
          </div>
        </div>
      )}
    </div>
  );
};



const Events = () => {
  const upcomingShows = [
    {
      image: latestGig,
      place: "Solatis Lounge | Vanderbijlpark",
      date: "12 May 2024",
      time: "17:00 PM Till 19:00 PM",
      description:
        "Join us for the All Mother's Day Event!",
      url: "",
    },
    
    {
      image: Event1,
      place: "All Girls on Deck",
      date: "04/06 April",
      time: "",
      description:
        "Catch EuphoRea at Mcafe this saturday on All girls on Deck",
      url: "",
    },

    {
      image: LatestGig4,
      place: "YFM Radio (In the Ring)",
      date: "March 2024",
      time: "10:00 PM",
      description:
        "Vote for Euphorea's Mix at Yfm (In the Ring)",
      url: "https://twitter.com/Yfm/status/1774179818902294799?t=DmPFk0_GNG6npOEUfAH6lw&s=19",
    },

    

    {
      image: LatestGig3,
      place: "SE Boulevard | Vanderbijlpark",
      date: "02 March 2024",
      time: "8:00 PM",
      description:
        "Join us at Bubbles Brunch and RnB!",
      url: "",
    },


    // {
    //   image: Gig,
    //   place: "Festive Guide",
    //   date: "",
    //   time: "",
    //   description: "Come join us as we bring bangers to your ears",
    //   url: "",
    // },
    // {
    //   image: image5,
    //   place: "Houze Blaze Tv",
    //   date: "20 Jan 2024",
    //   time: "",
    //   description: "Join Euphorea during Ra Ole Ep Launch with Houze Blaze Tv ",
    //   url: "https://youtube.com/@houzeblazetv?si=khu3bCHvMvSKLxQr",
    // },
    // {
    //   image: image6,
    //   place: "Bolar Car Wash",
    //   date: "10 Dec 2023",
    //   time: "",
    //   description: "let's Groove together and let the music shine",
    //   url: "",
    // },
  ];


  return (
    <div>
      <div className="mt-8 ml-3 lg:ml-28 mx-auto ">
        <h2 className="text-3xl font-bold text-center mb-16">Latest Events</h2>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 xl:grid-cols-3 gap-10 mx-auto mt-8">
          {upcomingShows.map((show, index) => (
            <UpcomingShow
              key={`show-${index}`}
              image={show.image}
              place={show.place}
              date={show.date}
              time={show.time}
              description={show.description}
              url={show.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
