import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Yourcart({ isOpen, onClose, cartItems }) {
  const navigate = useNavigate(); 
  const [cart, setCart] = useState(cartItems);
  
  const shippingCost = 100;
  const [quantities, setQuantities] = useState(
    cartItems.reduce((quantityMap, item) => {
      quantityMap[item.id] = 1;
      return quantityMap;
    }, {})
  );
  

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []);

  const calculateSubtotal = () => {
    let subtotal = 0;
    for (const item of cart) {
      subtotal += item.price * quantities[item.id];
    }
    return subtotal;
  };

  const subtotal = calculateSubtotal();

  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity >= 1) {
      // Update cart in state and local storage
      const updatedCart = cart.map((item) =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      );
      setCart(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
  
      // Update quantities state
      setQuantities({
        ...quantities,
        [itemId]: newQuantity,
      });
    }
  };

  const handleRemoveItem = (itemId) => {
    // Filter out the item with the given ID and update the cart items and quantities
    const updatedCart = cart.filter((item) => item.id !== itemId);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    toast.success("Item Has been Removed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handlePlaceOrder = () => {
    // Pass the cart items to the Checkout page and navigate to it
    navigate("/checkout", { state: { cart: cart } });
  };
  const isCartEmpty = cart.length === 0;

  return (
    <>
      <ToastContainer />
      
      <div
        className={`fixed top-0 right-0 w-full sm:w-96 bg-white shadow-lg transform transition-transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="p-4">
          <h1 className="text-xl font-semibold text-gray-900">Your Cart</h1>
        </div>
        <div className="p-4 overflow-y-auto max-h-80">
          {isCartEmpty ? (
            <p className="text-gray-400 text-center">Your Cart is empty.</p>
          ) : (
            <ul className="-my-8">
              {cart.map((item, index) => (
                <li
                  key={index}
                  className="relative flex flex-col space-y-3 py-6 text-left sm:flex-row sm:space-x-5 sm:space-y-0"
                >
                  <div className="shrink-0">
                    <span className="absolute top-1 left-1 flex h-6 w-6 items-center justify-center rounded-full border bg-white text-sm font-medium text-gray-500 shadow sm:-top-2 sm:-right-2">
                      {index + 1}
                    </span>
                    <img
                      className="h-24 w-24 max-w-full rounded-lg object-cover"
                      src={item.img}
                      alt={item.name}
                    />
                  </div>

                  <div className="flex flex-1 flex-col justify-between">
                    <div className="pr-8">
                      <p className="text-base font-semibold text-gray-900">
                        {item.title}
                      </p>
                      <p className="mx-0 mt-1 mb-0 text-sm text-gray-400">
                        {item.category}
                      </p>
                    </div>

                    <div className="mt-4 flex items-end justify-between sm:mt-0 sm:items-start sm:justify-end">
                      <div className="flex items-center">
                        <button
                          className="px-2 py-1 bg-gray-100"
                          onClick={() =>
                            handleQuantityChange(
                              item.id,
                              quantities[item.id] - 1
                            )
                          }
                        >
                          -
                        </button>
                        <input
                          className="w-8 text-center border rounded mx-1"
                          type="text"
                          value={quantities[item.id]}
                          readOnly
                        />
                        <button
                          className="px-2 py-1 bg-gray-100"
                          onClick={() =>
                            handleQuantityChange(
                              item.id,
                              quantities[item.id] + 1
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                      <p className="shrink-0 w-20 text-base font-semibold text-gray-900 sm:order-2 sm:ml-8 sm:text-right">{`R${(
                        item.price * quantities[item.id]
                      ).toFixed(2)}`}</p>
                    </div>
                  </div>

                  <div className="absolute top-6 right-0">
                    <button
                      className="text-red-600 hover:text-red-800 focus:outline-none"
                      onClick={() => handleRemoveItem(item.id)}
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="p-4 border-t border-gray-300">
          {isCartEmpty ? (
            <button
              className="group inline-flex w-full items-center justify-center rounded-md bg-gradient-to-r from-indigo-500 to-purple-500 px-4 py-2 text-base font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800"
              onClick={onClose}
            >
              Continue Shopping
            </button>
          ) : (
            <div>
              <div className="flex justify-between">
                <p className="text-gray-400">Subtotal</p>
                <p className="text-lg font-semibold text-gray-900">{`R${subtotal.toFixed(
                  2
                )}`}</p>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-400">Shipping</p>
                <p className="text-lg font-semibold text-gray-900">{`R${shippingCost.toFixed(
                  2
                )}`}</p>
              </div>
            </div>
          )}
        </div>
        {!isCartEmpty && (
          <div className="p-4 text-center">
            <button
              type="button"
              className="group inline-flex w-full items-center justify-center rounded-md bg-gradient-to-r from-indigo-500 to-purple-500 px-4 py-2 text-base font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800"
              onClick={handlePlaceOrder}
            >
              Place Order
            </button>
          </div>
        )}
        <button
          type="button"
          className="absolute top-4 right-4 text-gray-500 focus:outline-none hover:text-gray-900"
          onClick={onClose}
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
    </>
  );
}