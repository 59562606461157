import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import { getAuth } from "firebase/auth";

import { db } from "../../Database/Firebase";
import { collection, doc, updateDoc, deleteDoc, setDoc, getDoc} from "firebase/firestore";
function Account() {

  const [email, setEmail] = useState("")
  const [country, setCountry] = useState("");  
  const [userData, setUserData] = useState(null);
  const [ShowDeleteConfirmation, setShowDeleteConfirmation] = useState(false);


  const auth = getAuth();

  useEffect(() => {
    fetchUserData();
  }, []);

  const onUpdatePressed = async () => {
    const user = auth.currentUser;
    setShowDeleteConfirmation(false);
    if (user) {
      try {
        

        // Checks firstName, lastName, and phoneNumber have valid values
        if (!email ) {
          console.warn("Please fill all required fields.");
          return;
        }

        // Update user details in Firestore using the collection method
        const userRef = doc(db, "users", user.email, "UserDetails",);
        await updateDoc(userRef, {
          country,
        });

        alert("User profile and details updated successfully!");
      } catch (error) {
        alert("Error updating user profile and details:", error);
      }
    }
  };

  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userRef = doc(db, "users", user.email);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserData({ ...docSnap.data(), id: docSnap.id });      
          setEmail(docSnap.data().email);
          setCountry(docSnap.data().country);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  return (
    <div className="w-full p-8">
      <h1 className="text-3xl font-semibold mb-8">Account</h1>
      <div className="grid grid-cols-2 gap-6">
        
        <div className="col-span-2">
          <label className="text-gray-600">Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="user@example.com"
          />
        </div>
        
        <div>
          <label className="text-gray-600">Country</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            placeholder="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>
      </div>
      

      <button
        className="bg-blue-500 text-white px-4 py-2 mt-8 rounded-full"
        onChange={onUpdatePressed}
      >
        Save Changes
      </button>

      
    </div>
  );
}

export default Account;
