import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../Database/Firebase';
import { getDocs, collection } from 'firebase/firestore';
import Pagination from './Pagination';
import AdminNavBar from './NavBar';

const ViewAllTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust as needed

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'transactions'));
                const transactionsData = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    const date = data.timestamp.toDate();
                    const totalPrice = data.totalPrice
                    const status = data.status
                    const name = data.name
                    const amount = data.totalPrice
                    const transactionid = data.orderNumber
                    
                    // Add the converted date to the data object
                    return { ...data, date, totalPrice, status, transactionid, amount, name };
                });
                setTransactions(transactionsData);
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };

        fetchData();
    }, []);

    const getTransactionDescription = (transaction) => {
        switch (transaction.transactionType) {
            case 'payment':
                return `Payment received from ${transaction.name || transaction.Invoice}`;
            case 'refund':
                return `Payment refund to ${transaction.name || transaction.Invoice}`;
            case 'failed':
                return `Payment failed from ${transaction.name || transaction.Invoice}`;
            default:
                return '';
        }
    };

    // Pagination logic
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedTransactions = transactions.slice(startIndex, endIndex);

    const sendNotificationEmail = async (transaction) => {
        // Configure email sending library with your credentials
        const transporter = "";

        // Compose email content based on transaction data
        const subject = `Euphorea, Update on your ${transaction.transactionType} payment`;
        const message = `
          Hi ${transaction.name},
      
          We're writing to inform you about the status of your recent ${transaction.transactionType} for ${transaction.amount}.
      
          Current status: ${transaction.status}
      
          for any queries regading your order please let us know in our email: info.euphorea@gmail.co.za
      
          Sincerely,
      
          Euphorea Team
        `;

        // Send the email
        const options = {
            from: 'info.euphorea@gmail.co.za',
            to: transaction.email,
            subject,
            text: message,
        };
        try {
            await transporter.sendMail(options);
            console.log(`Email sent to ${transaction.email}`);
        } catch (error) {
            console.error(`Error sending email: ${error}`);
        }
    };

    return (
        <div>
            <AdminNavBar />
            <div className='bg-white shadow-md rounded-lg mb-4 p-4 sm:p-6 h-full mt-20'>
                <div className="mb-4 flex items-center justify-between">
                    <div>
                        <h3 className="text-xl font-bold text-gray-900 mb-2">All Transactions</h3>
                        <span className="text-base font-normal text-gray-500">This is a list of all transactions</span>
                    </div>
                    <div className="flex-shrink-0">
                        <Link to="/Admin/Dashboard" className="text-sm font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2">Go back</Link>
                    </div>
                </div>

                <div className="flex flex-col mt-8">
                    <div className="overflow-x-auto rounded-lg">
                        <div className="align-middle inline-block min-w-full">
                            <div className="shadow overflow-hidden sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Transaction
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Date & Time
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Amount
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Status
                                            </th>

                                        </tr>
                                    </thead>

                                    <tbody className="bg-white">
                                        {paginatedTransactions.map((transaction) => (
                                            <tr key={transaction.paymentid}>
                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                                    {transaction}
                                                    <span className="font-semibold"></span>
                                                </td>

                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                                    {transaction.date.toLocaleString()}
                                                </td>
                                                <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                                                    R {transaction.amount}
                                                </td>

                                                <td className={
                                                    `p-4 whitespace-nowrap text-sm font-normal text-gray-900 ${transaction.status === "pending" || transaction.status === "failed"
                                                        ? "pending-button"
                                                        : "completed-button"
                                                    }`
                                                }>
                                                    {getTransactionDescription(transaction)}
                                                    <span className="font-semibold"> - {transaction.status}</span>
                                                    {transaction.status !== "completed" && (
                                                        <button onClick={() => sendNotificationEmail(transaction)}>Notify</button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Pagination component */}
            <Pagination
                totalItems={transactions.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default ViewAllTransactions;
