import React from 'react'

const users = [
    {
        id: 1,
        Clientname: "Bonnie Green",
        Invoice: "INVhf15U",
        amount: 99.00,
        transactionType: "payment",
        date: "Apr 23 ,2021"

    },

    {
        id: 2,
        Clientname: "THEMESBERG LLC",
        Invoice: "INV3vyruf",
        amount: 150.00,
        transactionType: "refund",
        date: "Apr 15 ,2021	"
    },

    {
        id: 3,
        Clientname: "Jese Leos",
        Invoice: "INVe73h15U",
        amount: 200.00,
        transactionType: "payment",
        date: "Apr 15 ,2021	"
    },

    {
        id: 4,
        Clientname: "Kaymoo",
        Invoice: "INV2vU3GU",
        amount: 99.00,
        transactionType: "failed",
        date: "feb 20 ,2021	"
    },
];

const Accuisitions = () => {
    return (
        <div className='bg-white shadow-md rounded-lg mb-4 p-4 sm:p-6 h-full'>
            <h3 className="text-xl leading-none font-bold text-gray-900 mb-10">Acquisition Overview</h3>
            <div className="block w-full overflow-x-auto">
                <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th className="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">Top Channels</th>
                            <th className="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">Users</th>
                            <th className="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap min-w-140-px"></th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100">
                        <tr className="text-gray-500">
                            <th className="border-t-0 px-4 align-middle text-sm font-normal whitespace-nowrap p-4 text-left">Organic Search</th>
                            <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">5,649</td>
                            <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                                <div className="flex items-center">
                                    <span className="mr-2 text-xs font-medium">30%</span>
                                    <div className="relative w-full">
                                        <div className="w-full bg-gray-200 rounded-sm h-2">
                                            <div className="bg-cyan-600 h-2 rounded-sm" ></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className="text-gray-500">
                            <th className="border-t-0 px-4 align-middle text-sm font-normal whitespace-nowrap p-4 text-left">Referral</th>
                            <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">4,025</td>
                            <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                                <div className="flex items-center">
                                    <span className="mr-2 text-xs font-medium">24%</span>
                                    <div className="relative w-full">
                                        <div className="w-full bg-gray-200 rounded-sm h-2">
                                            <div className="bg-orange-300 h-2 rounded-sm" ></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className="text-gray-500">
                            <th className="border-t-0 px-4 align-middle text-sm font-normal whitespace-nowrap p-4 text-left">Direct</th>
                            <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">3,105</td>
                            <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                                <div className="flex items-center">
                                    <span className="mr-2 text-xs font-medium">18%</span>
                                    <div className="relative w-full">
                                        <div className="w-full bg-gray-200 rounded-sm h-2">
                                            <div className="bg-teal-400 h-2 rounded-sm" ></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className="text-gray-500">
                            <th className="border-t-0 px-4 align-middle text-sm font-normal whitespace-nowrap p-4 text-left">Social</th>
                            <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">1251</td>
                            <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                                <div className="flex items-center">
                                    <span className="mr-2 text-xs font-medium">12%</span>
                                    <div className="relative w-full">
                                        <div className="w-full bg-gray-200 rounded-sm h-2">
                                            <div className="bg-pink-600 h-2 rounded-sm" ></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                        <tr className="text-gray-500">
                            <th className="border-t-0 px-4 align-middle text-sm font-normal whitespace-nowrap p-4 text-left">Other</th>
                            <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">734</td>
                            <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                                <div className="flex items-center">
                                    <span className="mr-2 text-xs font-medium">9%</span>
                                    <div className="relative w-full">
                                        <div className="w-full bg-gray-200 rounded-sm h-2">
                                            <div className="bg-indigo-600 h-2 rounded-sm" ></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className="text-gray-500">
                            <th className="border-t-0 align-middle text-sm font-normal whitespace-nowrap p-4 pb-0 text-left">Email</th>
                            <td className="border-t-0 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4 pb-0">456</td>
                            <td className="border-t-0 align-middle text-xs whitespace-nowrap p-4 pb-0">
                                <div className="flex items-center">
                                    <span className="mr-2 text-xs font-medium">7%</span>
                                    <div className="relative w-full">
                                        <div className="w-full bg-gray-200 rounded-sm h-2">
                                            <div className="bg-purple-500 h-2 rounded-sm" ></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>


                        <tr className="text-gray-500 ">
                            <th className="border-t-0 align-middle text-md font-bold whitespace-nowrap p-4 pb-0 text-left ">Total Users</th>
                            <td className="border-t-0 align-middle text-sm font-medium text-gray-900 whitespace-nowrap p-4 pb-0">{users.length}</td>

                            <div className="flex items-center">
                                <span className="mr-2 text-xs font-medium"></span>
                                <div className="relative w-full">
                                    <div className="w-ful rounded-sm h-2">
                                    </div>
                                </div>
                            </div>
                        </tr>



                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Accuisitions