import React, { useState } from "react";
import NavBar from "../Components/NavBar";
import Euphorea from "../Assets/Images/Momza.jpg";
import { motion } from "framer-motion";

const About = () => {
  const [cart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  return (
    <div>
      <NavBar
        onAddToCart={() => setIsCartOpen(!isCartOpen)}
        cartCount={cart.length}
        cartItems={cart}
      />
      <div className="container mx-auto mt-32">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2">
            <img
              src={Euphorea}
              alt="Artist"
              className="w-3/4 h-100 max-w-lg mx-auto rounded-lg shadow-lg shadow-slate-300"
            />
          </div>

          <div className="md:w-1/2 lg:mt-8 sm:mt-10 md:mt-5 ml-5">
            <h1 className="text-4xl mb-4 mt-5 text-center ">
              About <motion.strong whileHover={{color:"rgb(192 143 10 / 95%)"}} className="">Euphorea</motion.strong>
            </h1>
            <p className="text-lg mb-4">
              <span className="text-amber-600 text-2xl ">Reatletsoe Buang</span>, also known as EuphoRea, is a talented DJ hailing
              from the town of Heilbron in Free State, South Africa. She began
              her journey as a DJ in mid-October 2022 and quickly made a name
              for herself in the local music scene. A few months later, she
              joined the House Summit movement, which was hosted every Thursday
              and focused on deep house music.
            </p>

            <p className="text-lg mb-4">
              As time went on, Reatletsoe decided to build her brand
              individually and grow as a DJ. She is passionate about creating
              memories for her audience through music and has been inspired by
              Atjazz, Charles Webster, Erric Kupper, Jimpster, and many more.
              Her favorite producers include YoungMolz, M.K Clive, June Jazzin,
              Dav Risen, Timadeep, and Radic The Myth.
            </p>

            <p className="text-lg mb-4">
              Reatletsoe’s music style is a blend of Deep house, Soulful House,
              and some Jazzy-funk tunes. She is always looking for new
              opportunities to showcase her talent and connect with other music
              lovers.
            </p>

            <p className="text-lg">
              Connect with Euphorea on social media to stay updated with her
              latest creations.
            </p>
            <div className="flex mt-4">
              <a
                href="https://www.instagram.com/euphorea_worldwide/"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-4 text-indigo-500 hover:text-purple-700"
              >
                Instagram
              </a>
              <a
                href="https://twitter.com/MomosiBuang"
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-500 hover:text-indigo-700"
              >
                Twitter
              </a>
            </div>

            <div className="mt-8">
              <h2 className="text-2xl mb-2 text-amber-600">Hobbies</h2>
              <ul className="list-disc ml-6">
                <li>Always collecting new music</li>
                <li>Composing music</li>
                <li>Attending events</li>
              </ul>
            </div>

            <div className="mt-8 mb-10">
              <h2 className="text-2xl mb-2 text-amber-600">Likes</h2>
              <ul className="list-disc ml-6">
                <li>Listen to music </li>
                <li>Cooking</li>
                <li>Networking</li>
                <li>Outdoor activities</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
