import { useNavigate } from "react-router-dom";

const BlogDetail = ({ blog, id, imageUrl, author, content, date, title }) => {
    
    const navigate = useNavigate();
  
    const handleGoBack = () => {
      navigate("/Blogs");
    };
  
    return (
      <div className="bg-white font-[sans-serif] p-4 mt-8 md:mt-32">
        <div className="max-w-7xl flex flex-col md:flex-row">
          <div className="w-full md:w-3/4 mb-4 md:mb-0">
            <img
              src={imageUrl}
              alt={`Blog Post ${blog.id}`}
              className="w-full h-auto rounded-lg shadow-lg "
            />
          </div>
  
          <div className="w-full md:w-1/2 md:pl-8">
            <div className="text-center max-w-xl mx-auto">
              <h2 className="text-3xl font-extrabold text-[#333] inline-block">
                {blog.title}
              </h2>
              <p className="text-gray-600 text-sm mt-6">
                {blog.date} | BY {author}
              </p>
            </div>
            <div className="mt-8">
              {blog.paragraphs.map((paragraph) => (
                <div key={paragraph.id} className="mb-4">
                  {paragraph.imageUrl && (
                    <img
                      src={paragraph.imageUrl}
                      alt={`Paragraph ${paragraph.id}`}
                      className="mb-2 w-full h-64 md:h-72 lg:h-96 object-contain"
                    />
                  )}
                  <p className="text-gray-800 text-lg">{paragraph.content}</p>
                </div>
              ))}
            </div>
            <h2 className="mt-8 font-semibold">Conclusion</h2>
            <p className="text-gray-600 text-md mt-6">{blog.conclusion}</p>
            {/* <button
              className="mt-8 bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600"
              onClick={handleGoBack}
            >
              Back
            </button> */}
          </div>
        </div>
      </div>
    );
  };

  export default BlogDetail