import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

const YouTube = ({ videoId }) => {
  return (
    <div className="col-span-1 mb-5">
      <iframe
        title="YouTube Video"
        className=" w-80 h-96"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const YouTubeVideos = () => {
  const youtubeData = [
    { videoId: "MYyLsl6DvfE?si=gh4LHkZJb0yllOnG" },
    { videoId: "DeNLZ88ux-o?si=l6yM-ipOrl9iMfEB" },
    { videoId: "Lomy20pz0cQ?si=CVtweFl8tY6wMuTP" },
  ];

  return (
    <div className="mt-10">
      <h2 className="text-3xl font-bold text-center mb-10 col-span-full">
        My YouTube Videos
      </h2>
      <div className="mt-16 mx-auto ml-8 grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:ml-28 mb-10 ">
        {youtubeData.map((data, index) => (
          <YouTube key={index} videoId={data.videoId} />
        ))}
      </div>

    </div>
  );
};

export default YouTubeVideos;