import React, { useState } from "react";
import NavBar from "../Components/NavBar";
import { useNavigate } from "react-router-dom";

const blogs = [
  {
    id: 1,
    url: "Dive_into_the_depths_of_deephouse",
    date: "10 Nov 2023",
    author: "Euphorea",
    title: "Dive into the Depths of Deep House",
    imageUrl:
      "https://geo-static.traxsource.com/files/images/2ac9c4abf5d8365df50f33e26454b53d.jpg",
    introduction:
      "Embark on a sonic journey into the immersive world of Deep House.",
    paragraphs: [
      {
        id: 1,
        content:
          "From its origins in the underground scene to its influence on contemporary electronic music, Deep House has captured the hearts of music enthusiasts worldwide.",
        imageUrl: "",
      },
      {
        id: 2,
        content:
          "With its hypnotic beats, soulful melodies, and the unique atmosphere it creates, Deep House has become a genre that transcends boundaries and connects people through the power of music.",
        imageUrl: "",
      },
      {
        id: 3,
        content:
          "Dive deeper into the world of Deep House and you'll discover a rich tapestry of artists, labels, and sub-genres. Whether it's the deep basslines, ethereal synths, or the infectious groove, Deep House has a way of captivating listeners and taking them on a journey of sonic exploration.",
        imageUrl: "",
      },
      {
        id: 4,
        content:
          "Join me as we explore the history of Deep House, from its roots in Chicago and New York to its global influence today. We'll delve into the iconic tracks, legendary DJs, and the evolution of the genre over the years. Get ready to immerse yourself in the rhythmic waves of electronic bliss.",
        imageUrl: "",
      },
    ],
    conclusion:
      "Whether you're a seasoned Deep House enthusiast or new to the genre, this blog is your gateway to the captivating world of Deep House. Let the music guide you as we dive into the depths of this mesmerizing genre.",
  },
  {
    id: 2,
    url: "Soulful_Sounds",
    date: "15 Nov 2023",
    author: "Euphorea",
    title: "Soulful Sounds: A Celebration of Music with Soul",
    imageUrl:
      "https://travel2next.com/wp-content/uploads/beaches-near-siem-reap-cambodia.jpg",
    introduction: "Join me in celebrating the essence of soulful music.",
    paragraphs: [
      {
        id: 1,
        content:
          "Soulful music touches the depths of our hearts and resonates with our emotions.",
        imageUrl: "",
      },
      {
        id: 2,
        content:
          "With heartfelt lyrics, expressive melodies, and powerful vocals, soulful music has the ability to uplift, inspire, and heal.",
        imageUrl: "",
      },
      {
        id: 3,
        content:
          "Join me as we explore the stories behind iconic soul artists and their timeless songs. We'll dive into the influences that shaped the genre, from gospel and R&B to jazz and blues. Discover the artists who pour their emotions into every note, the cultural impact of soulful tunes, and how they continue to inspire generations.",
        imageUrl: "",
      },
      {
        id: 4,
        content:
          "Soulful music is more than just a genre; it's a form of expression that connects people across cultures and generations. Whether you're dancing to a classic soul anthem or finding solace in a heartfelt ballad, soulful sounds have the power to uplift, inspire, and heal. Let the music touch your soul as we celebrate the beauty of music with soul.",
        imageUrl: "",
      },
    ],
    conclusion:
      "Get ready to embark on a soulful journey through the timeless melodies, powerful voices, and heartfelt lyrics that define the genre. Join me as we celebrate the artists who have left an indelible mark on the world of music with their soulful sounds.",
  },
];

const BlogDetail = ({ blog }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/Blogs");
  };

  return (
    <div className="bg-white font-[sans-serif] p-4 mt-8 md:mt-32">
      <div className="max-w-7xl flex flex-col md:flex-row">
        <div className="w-full md:w-3/4 mb-4 md:mb-0">
          <img
            src={blog.imageUrl}
            alt={`Blog Post ${blog.id}`}
            className="w-full h-auto rounded-lg shadow-lg "
          />
        </div>

        <div className="w-full md:w-1/2 md:pl-8">
          <div className="text-center max-w-xl mx-auto">
            <h2 className="text-3xl font-extrabold text-[#333] inline-block">
              {blog.title}
            </h2>
            <p className="text-gray-600 text-sm mt-6">
              {blog.date} | BY {blog.author}
            </p>
          </div>
          <div className="mt-8">
            {blog.paragraphs.map((paragraph) => (
              <div key={paragraph.id} className="mb-4">
                {paragraph.imageUrl && (
                  <img
                    src={paragraph.imageUrl}
                    alt={`Paragraph ${paragraph.id}`}
                    className="mb-2 w-full h-64 md:h-72 lg:h-96 object-contain"
                  />
                )}
                <p className="text-gray-800 text-lg">{paragraph.content}</p>
              </div>
            ))}
          </div>
          <h2 className="mt-8 font-semibold">Conclusion</h2>
          <p className="text-gray-600 text-md mt-6">{blog.conclusion}</p>
          {/* <button
            className="mt-8 bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600"
            onClick={handleGoBack}
          >
            Back
          </button> */}
        </div>
      </div>
    </div>
  );
};

const Blogs = () => {
  const [cart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const navigate = useNavigate();

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);

    navigate(`/blogs/${blog.url}`);
  };

  // const handleBackButtonClick = () => {
  //   setSelectedBlog(null);
  // };

  return (
    <div>
      <NavBar
        onAddToCart={() => setIsCartOpen(!isCartOpen)}
        cartCount={cart.length}
        cartItems={cart}
      />
      
      {selectedBlog ? (
        <BlogDetail blog={selectedBlog} />
      ) : (
        <div className="bg-white font-[sans-serif] p-4 mt-32">
          <div className="max-w-7xl mx-auto">
            <div className="text-center max-w-xl mx-auto">
              <h2 className="text-3xl font-extrabold text-[#333] inline-block">
                LATEST BLOGS
              </h2>
              <p className="text-gray-600 text-sm mt-6">
                Explore the world of music through our latest blogs.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12 max-md:max-w-lg mx-auto">
              {blogs.map((blog) => (
                <div
                  key={blog.id}
                  className="bg-white cursor-pointer rounded overflow-hidden group relative before:absolute before:inset-0 before:z-10 before:bg-black before:opacity-50"
                  onClick={() => handleBlogClick(blog)}
                >
                  <img
                    src={blog.imageUrl}
                    alt={`Blog Post ${blog.id}`}
                    className="w-full h-64 md:h-72 lg:h-96 object-stretch group-hover:scale-110 transition-all duration-300"
                  />
                  <div className="p-6 absolute bottom-0 left-0 right-0 z-20">
                    <span className="text-sm block mb-2 text-yellow-400 font-semibold">
                      {blog.date} | BY {blog.author}
                    </span>
                    <h3 className="text-xl font-bold text-white">
                      {blog.title}
                    </h3>
                    <div className="mt-4">
                      <p className="text-gray-200 text-sm">{blog.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blogs;
