import { useState, useEffect } from 'react'
import { db } from '../../Database/Firebase';
import { getDocs, collection } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Viewtransactions = () => {

  // const transactions = [
  //     {
  //         paymentid: 1,
  //         Clientname: "Bonnie Green",
  //         Invoice: "INVhf15U",
  //         amount: 99.00,
  //         transactionType: "payment",
  //         date: "Apr 23 ,2021"
  //     },

  //     {
  //         paymentid: 2,
  //         Clientname: "THEMESBERG LLC",
  //         Invoice: "INV3vyruf",
  //         amount: 150.00,
  //         transactionType: "refund",
  //         date: "Apr 15 ,2021	"
  //     },

  //     {
  //         paymentid: 3,
  //         Clientname: "Jese Leos",
  //         Invoice: "INVe73h15U",
  //         amount: 200.00,
  //         transactionType: "payment",
  //         date: "Apr 15 ,2021	"
  //     },

  //     {
  //         paymentid: 4,
  //         Clientname: "Kaymoo",
  //         Invoice: "INV2vU3GU",
  //         amount: 99.00,
  //         transactionType: "failed",
  //         date: "feb 20 ,2021	"
  //     },
  // ];

  // let url = "https//pos.snapscan.io/merchant/api/v1/payments";
  // let apiKey = "Api key here";

  // let headers = new Headers();
  // headers.append("Content-Type", "application/json");
  // headers.append("Authorization", `Basic ${btoa(apiKey + ":")}`);
  // fetch(url, {method: "GET", headers: headers})
  // .then(response => response.json())
  // .then(data =>  console.log(data));

  // const filterByType = (transactionType) => {
  //     return transactions.filter((transaction) => transactions.transactionType === transactionType);
  // };

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'orders'));
        const transactionsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          // Convert Firestore timestamp to JavaScript Date
          const date = data.timestamp.toDate();
          const totalPrice = data.totalPrice
          const status = data.status
          const orderNumber = data.orderNumber
          // Add the converted date to the data object
          return { ...data, date, totalPrice, status, orderNumber };
        });
        // Sort transactions by date in descending order
        const sortedTransactions = transactionsData.sort((a, b) => b.date - a.date);
        // Get the latest 5 transactions
        const latestTransactions = sortedTransactions.slice(0, 5);
        setTransactions(latestTransactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className='bg-white shadow-md rounded-lg mb-4 p-4 sm:p-6 h-full '>
      <div className="mb-4 flex items-center justify-between">
        <div>
          <h3 className="text-xl font-bold text-gray-900 mb-2">Latest Transactions</h3>
          <span className="text-base font-normal text-gray-500">This is a list of latest transactions</span>
        </div>
        <div className="flex-shrink-0">
          <Link to="/Admin/ViewAllTransactions" className="text-sm font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2">View all</Link>
        </div>
      </div>

      <div className="flex flex-col mt-8">
        <div className="overflow-x-auto rounded-lg">
          <div className="align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Transaction
                    </th>
                    <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date & Time
                    </th>
                    <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {transactions &&
                    transactions.map((transaction) => (
                      <tr key={transaction.orderNumber} className={getHighlightClass(transaction.status)}>
                        <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                          {getTransactionDescription(transaction)}
                          <span className="font-semibold">
                            {/* <Link to={`/Admin/ViewTransaction/${transaction.orderNumber}`}> */}
                              {transaction.orderNumber}
                            {/* </Link> */}
                          </span>
                        </td>

                        <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                          {transaction.date.toLocaleString()}
                        </td>
                        <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                          R {transaction.totalPrice}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Helper function to get the highlight class based on transaction type
const getHighlightClass = (transactionType) => {
  switch (transactionType) {
    case 'complete':
      return 'bg-green-100';
    case 'pending':
      return 'bg-yellow-100';
    case 'failed':
      return 'bg-red-100';
    default:
      return '';
  }
};

// Helper function to get the transaction description based on transaction type
const getTransactionDescription = (transaction) => {
  switch (transaction.transactionType) {
    case 'complete':
      return `Payment received from ${transaction.firstname || transaction.orderNumber}`;
    case 'pending':
      return `Payment pending from ${transaction.firstname || transaction.OrderNumber}`;
    case 'failed':
      return `Payment failed from ${transaction.firstname || transaction.OrderNumber}`;
    default:
      return '';
  }
};

export default Viewtransactions;
