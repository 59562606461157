import { React, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Database/Firebase";
// import { useAuthState } from "react-firebase-hooks/auth";
import bg from "../../Assets/Images/Euphorealogo.jpg"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const AdminLogin = () => {
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const authorizedEmails = process.env.AUTHORIZED_EMAILS;

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const { email, password } = formData;

    function onChange(e) {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    }
    
    async function onSubmit(e) {
        e.preventDefault();
        try {
            const auth = getAuth();
            const userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            if (userCredential.user) {
                navigate("/Admin/Dashboard");
            }
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
        }
    }


    return (
        <div>
            <div className="flex flex-wrap">
                <div className="flex w-full flex-col md:w-1/2">
                    <div className="lg:w-[28rem] mx-auto my-auto flex flex-col justify-center pt-8 md:justify-start md:px-6 md:pt-0">
                        <p className="mt-2 text-center text-gray-500">
                            Welcome back <strong>Euphorea</strong>, please enter your details.
                        </p>
                        <form className="flex flex-col pt-3 md:pt-8" autoComplete="on" onSubmit={onSubmit}>
                            <div className="flex flex-col pt-4">
                                <div className="focus-within:border-b-gray-500 relative flex overflow-hidden border-b-2 transition">
                                    <input
                                        className="w-full flex-1 appearance-none border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        placeholder="Email address"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-12 flex flex-col pt-4">
                                <div className="focus-within:border-b-gray-500 relative flex overflow-hidden border-b-2 transition">
                                    <input
                                        className="w-full flex-1 appearance-none border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        placeholder="Password"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="w-full rounded-lg bg-gray-900 px-4 py-2 text-center text-base font-semibold text-white shadow-md ring-gray-500 ring-offset-2 transition focus:ring-2"

                            >
                                Log in
                            </button>
                        </form>

                    </div>
                </div>
                <div className="pointer-events-none relative hidden h-screen select-none bg-gray-900 md:block md:w-1/2">
                    <div className="absolute bottom-0 z-10 px-8 text-white opacity-100">
                        <p className="mb-8 text-2xl font-semibold leading-10">
                            In this world of deep house, I am the alchemist, the conductor
                            of emotions, and the weaver of dreams. I thrive on the
                            connection I forge with my audience, a symbiotic relationship
                            fueled by the collective love for the music. Together, we
                            create an electrifying synergy, an unbreakable bond that
                            transcends the boundaries of time and space.
                        </p>
                        <p className="mb-4 text-3xl font-semibold">Euphorea</p>
                        <p className="">Founder</p>
                        <p className="mb-7 text-sm opacity-70">Reatletsoe Buang</p>
                    </div>
                    <img
                        className="-z-1 absolute top-0 w-52 ml-80 rounded-lg h-auto mt-16 object-cover opacity-80"
                        src={bg}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
