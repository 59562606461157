import React, { useState } from "react";
import NavBar from "../Components/NavBar";
import Mywork from "../Components/Mixtapes";
import Footer from "../Components/Footer";
import Euphorea from "../Assets/Images/Euphorea.jpg";
import YouTubeVideos from "../Components/Youtube";
import Events from "../Components/Event";

const Home = () => {
  const [cart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  return (
    <div>
      <NavBar
        onAddToCart={() => setIsCartOpen(!isCartOpen)}
        cartCount={cart.length}
        cartItems={cart}
      />
      <div className=" bg-gradient-to-r from-indigo-500 to-purple-500 transition-transform duration-5000 transform bg-repeat-x bg-scroll">
        <div className="py-8 px-4  lg:px-8">
          <div className="max-w-3xl mx-auto">
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <img
                src={Euphorea}
                alt="BannerImage"
                className="w-full max-w-sm mx-auto "
              />
              <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-800">
                  Welcome to <b>Euphorea</b>
                </h2>
                <p className="mt-2 text-gray-600">
                  In this world of deep house, I am the alchemist, the conductor
                  of emotions, and the weaver of dreams. I thrive on the
                  connection I forge with my audience, a symbiotic relationship
                  fueled by the collective love for the music. Together, we
                  create an electrifying synergy, an unbreakable bond that
                  transcends the boundaries of time and space.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Events />
      <Mywork />
      <YouTubeVideos />
      <Footer />
    </div>
  );
};

export default Home;
