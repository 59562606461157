import React, { useState } from "react";

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageClick = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    return (
        <div className="bg-white sticky sm:flex items-center w-full sm:justify-between bottom-0 right-0 border-t border-gray-200 p-4">
            <div className="flex items-center mb-4 sm:mb-0">


                <span className="text-sm font-normal text-gray-500">
                    Showing <span className="text-gray-900 font-semibold">{Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)}</span> -
                    <span className="text-gray-900 font-semibold ml-1">{Math.min(currentPage * itemsPerPage, totalItems)}</span> of
                    <span className="text-gray-900 font-semibold ml-2">{totalItems}</span>
                </span>
                
                <button

                    onClick={() => handlePageClick(currentPage - 1)}
                    className={`text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center ${currentPage === 1 ? 'pointer-events-none' : ''}`}
                >
                    {/* Previous button icon */}
                    Previous
                </button>
                <button

                    onClick={() => handlePageClick(currentPage + 1)}
                    className={`text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center mr-2 ${currentPage === totalPages ? 'pointer-events-none' : ''}`}
                >
                    {/* Next button icon */}
                    Next
                </button>
            </div>
        </div>
    );
};

export default Pagination;
