import React from "react";

function CheckoutProgression() {
  const stepData = [
    { step: 1, label: "Shop", done: true },
    { step: 2, label: "Shipping", done: true },
    { step: 3, label: "Payment", done: false },
  ];

  return (
    <div>
      <div className="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
        <a href="/" className="text-2xl font-bold text-gray-800">
          Checkout
        </a>
        <div className="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
          <div className="relative">
            <ul className="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
              {stepData.map((stepInfo) => (
                <React.Fragment key={stepInfo.step}>
                  {stepInfo.step !== 1 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`h-4 w-4 text-${stepInfo.done ? "emerald" : "gray"}-400`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      {stepInfo.done && (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      )}
                    </svg>
                  )}
                  <li className="flex items-center space-x-3 text-left sm:space-x-4">
                    <a
                      className={`flex h-6 w-6 items-center justify-center rounded-full bg-${
                        stepInfo.done ? "emerald" : "gray-600"
                      } text-xs font-semibold text-${
                        stepInfo.done ? "white" : "gray-900"
                      } ring-${
                        stepInfo.done ? "emerald" : "gray-600"
                      } ring-offset-2`}
                      href={stepInfo.done ? "/" : stepInfo.step === 2 ? "shipping" : "payment"}
                    >
                      {stepInfo.done ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      ) : (
                        stepInfo.step
                      )}
                    </a>
                    <span
                      className={`font-semibold text-${
                        stepInfo.done ? "gray-900" : "gray-500"
                      }`}
                    >
                      {stepInfo.label}
                    </span>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutProgression;
