import React from "react";

const OrderSummary = ({ cart, calculateTotalPrice }) => {
  return (
    <>
      <p class="text-xl font-medium">Order Summary</p>
      <p class="text-gray-400">
        Check your items. And select a suitable shipping method.
      </p>
      {cart.map((item, index) => (
        <div
          key={index}
          className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6"
        >
          <div className="flex flex-col rounded-lg bg-white sm:flex-row">
            <img
              className="m-2 h-24 w-28 rounded-md border object-cover object-center"
              src={item.img}
              alt={item.title}
            />
            <div className="flex w-full flex-col px-4 py-4">
              <span className="font-semibold">{item.title}</span>
              <span className="float-right text-gray-400">{item.category}</span>
              <p className="text-lg font-bold">R{item.price.toFixed(2)}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default OrderSummary;
