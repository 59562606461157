import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../Components/NavBar";

function Contact() {
  const [cart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const form = useRef();
  console.log(process.env);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message sent Sucessfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
        },
        (error) => {
          console.log(error.text);
          toast.error("Failed to send message", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      );
    e.target.reset();
  };

  // eslint-disable-next-line no-unused-vars
  const validateForm = () => {
    let isValid = true;

    // Validate required fields
    const requiredFields = [
      "user_name",
      "user_email",
      "user_cellphone",
      "subject",
      "message",
    ];

    requiredFields.forEach((field) => {
      const input = form.current[field];
      if (!input.value) {
        isValid = false;
        input.setCustomValidity("This field is required");
        input.reportValidity();
      } else {
        input.setCustomValidity("");
      }
    });

    return isValid;
  };

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <NavBar
          onAddToCart={() => setIsCartOpen(!isCartOpen)}
          cartCount={cart.length}
          cartItems={cart}
        />

        <div className="flex-grow">
          <ToastContainer />

          <section className="bg-white py-12 text-gray-700 sm:py-16 lg:py-20 mt-20">
            <div class="my-6">
              <div class="grid sm:grid-cols-2 items-center gap-16 p-8 mx-auto max-w-4xl bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333] font-[sans-serif]">
                <div>
                  <h1 class="text-3xl font-extrabold">Booking Enquiry Form</h1>
                  <p class="text-sm text-gray-400 mt-3">
                    Do have any Queries for a specific Event or Want a custom
                    Service ? Feel free to reach out!
                  </p>
                  <div class="mt-12">
                    <h2 class="text-lg font-extrabold">Email</h2>
                    <ul class="mt-3">
                      <li class="flex items-center">
                        <div class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            height="20px"
                            fill="#007bff"
                            viewBox="0 0 479.058 479.058"
                          >
                            <path
                              d="M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 0 1 5.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"
                              data-original="#000000"
                            />
                          </svg>
                        </div>
                        <a href="#0" class="text-[#007bff] text-sm ml-3">
                          <small class="block">Mail</small>
                          <strong>info.euphorea@gmail.com</strong>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <form class="ml-auo space-y-4" ref={form} onSubmit={sendEmail}>
                  <input
                    type="text"
                    placeholder="Name"
                    class="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]"
                    name="user_name"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    class="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]"
                    name="user_email"
                    required
                  />
                  <input
                    type="tel"
                    placeholder="Cellphone"
                    class="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]"
                    name="user_cellphone"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Subject"
                    class="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]"
                    name="subject"
                    required
                  />
                  <textarea
                    placeholder="Message"
                    rows="6"
                    class="w-full rounded-md px-4 border text-sm pt-2.5 outline-[#007bff]"
                    name="message"
                    required
                  ></textarea>
                  <button
                    type="submit"
                    class="text-white bg-[#007bff] hover:bg-blue-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full"
                    value="Send"
                  >
                    Send
                  </button>
                </form>

                
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Contact;
