import React from "react";

function Security() {
  return (
    <div className="w-full p-20">
      <h1 className="text-3xl font-semibold mb-8">Security</h1>
      <div className="mb-4">
        <label className="text-gray-600">Change Password</label>
        <input
          type="password"
          className="w-full p-2 border rounded"
          placeholder="New Password"
        />
      </div>
      <div className="mb-4">
        <label className="text-gray-600">Current Password</label>
        <input
          type="password"
          className="w-full p-2 border rounded"
          placeholder="Current Password"
        />
      </div>
      <button className="bg-blue-500 text-white px-4 py-2 rounded-full mt-10">
        Change Password
      </button>
      <button className="bg-red-500 text-white px-4 py-2 rounded-full ml-5 mt-10">
        Delete Account
      </button>
    </div>
  );
}

export default Security;
