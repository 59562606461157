import React, { useEffect, useState } from "react";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Errorpage from "./Components/Errorpage";
import Contact from "./Pages/Contact";
import Store from "./Pages/Store";
import Music from "./Pages/Music";
import Blogs from "./Pages/Blogs";
import About from "./Pages/About";
import MaintenancePage from "./Components/Maintenance";
import ProductDetails from "./Components/Store/ProductDetails";
import Checkout from "./Components/Cart items/Checkout";
import CheckoutCancel from "./Components/Cart items/CheckoutCancel";
import CheckoutNotify from "./Components/Cart items/CheckoutNotify";
import Checkoutsuccess from "./Components/Cart items/Checkoutsuccess";
// import { Auth } from "firebase/auth";
// import AdminHome from "./Admin/Pages/Admin";
import AdminDashboard from "./Admin/Pages/AdminDashboard";
import ProductsPage from "./Admin/Pages/ProductsPage";
import AdminLogin from "./Admin/Auth/Login";
import BlogsPage from "./Admin/Pages/Blogs";
// import ProtectedRoute from "./Admin/Auth/ProtectecRoutes";
import ViewAllTransactions from "./Admin/Components/ViewAllTransctions";
import Settings from "./Admin/Pages/Settings";
import Security from "./Admin/Pages/Security";
import Account from "./Admin/Pages/Account";
import ViewAllEmaills from "./Admin/Components/ViewAllEmails";
import BlogDetail from "./Pages/BlogDetail";
import EditProductPage from "./Admin/Components/ProductModel";
import NewProductPage from "./Admin/Components/NewProductPage";
import ViewOrders from "./Admin/Pages/Orders";
// import { AuthContextProvider } from "./Context/AuthContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ProtectedRoute from "./Admin/Auth/ProtectecRoutes";
import { Spinner } from "@material-tailwind/react";
import { Gallery } from "./Pages/Gallery";

const LoadingAnimation = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-slate-50">
      {/* <div className="animate-spin rounded-full h-28 w-28 border-t-2 border-b-2 border-indigo-500"></div> */}
      <Spinner className="h-28 w-28" color="indigo" />
    </div>
  );
};

function App() {
  // const [cart, setCart] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [maintenanceMode] = useState(true);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Simulating a delay of 2 seconds to showcase the loading animation
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <BrowserRouter>
      {isLoading && <LoadingAnimation />}

      {maintenanceMode ? (
        <MaintenancePage />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Merchandise" element={<Store />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Music" element={<Music />} />
          <Route path="/Blogs/*" element={<Blogs />} />
          <Route path="/BlogDetail/url" element={<BlogDetail />} />
          <Route path="/About" element={<About />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/checkoutsuccess" element={<Checkoutsuccess />} />
          <Route path="/checkoutcancel" element={<CheckoutCancel />} />
          <Route path="/checkoutnotify" element={<CheckoutNotify />} />
          <Route path="*" element={<Errorpage />} />
          {/* <Route path="/Confirmation" element={<Confirmation />} /> */}

          <Route path="Merchandise/:id" element={<ProductDetails />} />

          <Route path="/Admin/Dashboard" element={<AdminDashboard />} />
          <Route path="/Admin/ViewAllEmails" element={<ViewAllEmaills />} />
              <Route path="/Admin/Account" element={<Account />} />
              <Route path="/Admin/Security" element={<Security />} />
              <Route path="/Admin/Dashboard" element={<AdminDashboard />} />
              <Route path="/Admin/ProductsPage/*" element={<ProductsPage />} />
              <Route
                path="/Admin/NewProductsPage"
                element={<NewProductPage />}
              />
              <Route
                path="/Admin/EditProductsPage/:title"
                element={<EditProductPage />}
              />
              <Route path="/Admin/BlogsPage" element={<BlogsPage />} />
              <Route path="/Admin/Settings" element={<Settings />} />
              <Route
                path="/Admin/ViewAllTransactions"
                element={<ViewAllTransactions />}
              />
              <Route path="/Admin/Orders" element={<ViewOrders />} />

          <Route path="/Admin/Login" element={<AdminLogin />} />

          <Route
            path='/Admin/Dashboard'
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />

          {user ? (
            <>
              <Route path="/Admin/ViewAllEmails" element={<ViewAllEmaills />} />
              <Route path="/Admin/Account" element={<Account />} />
              <Route path="/Admin/Security" element={<Security />} />
              <Route path="/Admin/Dashboard" element={<AdminDashboard />} />
              <Route path="/Admin/ProductsPage/*" element={<ProductsPage />} />
              <Route
                path="/Admin/NewProductsPage"
                element={<NewProductPage />}
              />
              <Route
                path="/Admin/EditProductsPage/:title"
                element={<EditProductPage />}
              />
              <Route path="/Admin/BlogsPage" element={<BlogsPage />} />
              <Route path="/Admin/Settings" element={<Settings />} />
              <Route
                path="/Admin/ViewAllTransactions"
                element={<ViewAllTransactions />}
              />
              <Route path="/Admin/Orders" element={<ViewOrders />} />
            </>
          ) : (
            <Route
              path="/Admin/login"
              element={<Navigate to="/Admin/login" />}
            />
            
          )}

        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
