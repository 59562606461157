import React from "react";
import Track1 from "../Assets/Images/Euphorea.jpg";
import image3 from "../Assets/Images/Euphorea mix.jpg";
import Mix4 from "../Assets/Images/2024/Mix 4.jpg"
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

const Mixtapes = () => {
  const mixtapes = [
    {
      title: "EuphoRea harmony-groove-voyage-vol-2",
      image: Mix4,
      url: "https://hearthis.at/reatletsoe/harmony-groove-voyage-vol-2-2/",
    },
    {
      title: "EuphoRea Soul Serenade Sessions Vol 2",
      image: Track1,
      url: "https://hearthis.at/reatletsoe/euphoreas-soul-serenade-session-vol2-1/",
    },
    {
      title: "SOUL SERENADE SESSION",
      image: image3,
      url: "https://hearthis.at/reatletsoe/soul-serenade-session/",
    },
    {
      title: "Hamony Groove Voyage Vol. 1",
      image: image3,
      url: "https://hearthis.at/reatletsoe/hamony-groove-voyage-vol-1/",
    },
  ];

  const Mixtape = ({ title, image, url }) => {
    const handleGoToTrack = () => {
      if (url) {
        window.open(url, "_blank");
      }
    };

    return (
      <Card className="mt-20 w-96 shadow-md md:max-w-2xl transform hover:scale-105 duration-500 ease-in-out">
        <CardHeader color="blue-gray" className="relative h-56">
          <img
            src={image}
            alt="cardimage"
            className="h-96 w-full rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50"
          />
        </CardHeader>
        <CardBody>
          <Typography variant="h5" color="blue-gray" className="mb-2">
            {title}
          </Typography>
        </CardBody>
        <CardFooter className="pt-0">
          <Button onClick={handleGoToTrack}>Listen to Mix</Button>
        </CardFooter>
      </Card>
    );
  };

  return (
    <div className="mt-12 mb-8 mx-4 lg:mx-28">
      <h2 className="text-3xl font-bold text-center">Mixtapes</h2>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 xl:grid-cols-3 gap-10 mt-8">
        {mixtapes.map((mixtape, index) => (
          <Mixtape
            key={index}
            title={mixtape.title}
            image={mixtape.image}
            url={mixtape.url}
          />
        ))}
      </div>
    </div>
  );
};

const Mywork = () => {
  return (
    <div>
      <Mixtapes />
    </div>
  );
};

export default Mywork;