import React, { useState, useEffect } from "react";
import AdminNavBar from "../Components/NavBar";
import Pagination from "../Components/Pagination";
import { collection, getDocs } from "firebase/firestore";
import { doc, addDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../../Database/Firebase";
import EditModal from "../Components/EditModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import productsData from "../../Database/data/products";


const BlogsPage = ({ product }) => {

    const [products, setProducts] = useState([]); // Your product data
    const [searchTerm, setSearchTerm] = useState(""); // State for search term
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust as needed

    const blogs = [
        {
          id: 1,
          url: "Dive_into_the_depths_of_deephouse",
          date: "10 Nov 2023",
          author: "Euphorea",
          title: "Dive into the Depths of Deep House",
          imageUrl:
            "https://geo-static.traxsource.com/files/images/2ac9c4abf5d8365df50f33e26454b53d.jpg",
          introduction:
            "Embark on a sonic journey into the immersive world of Deep House.",
          paragraphs: [
            {
              id: 1,
              content:
                "From its origins in the underground scene to its influence on contemporary electronic music, Deep House has captured the hearts of music enthusiasts worldwide.",
              imageUrl: "",
            },
            {
              id: 2,
              content:
                "With its hypnotic beats, soulful melodies, and the unique atmosphere it creates, Deep House has become a genre that transcends boundaries and connects people through the power of music.",
              imageUrl: "",
            },
            {
              id: 3,
              content:
                "Dive deeper into the world of Deep House and you'll discover a rich tapestry of artists, labels, and sub-genres. Whether it's the deep basslines, ethereal synths, or the infectious groove, Deep House has a way of captivating listeners and taking them on a journey of sonic exploration.",
              imageUrl: "",
            },
            {
              id: 4,
              content:
                "Join me as we explore the history of Deep House, from its roots in Chicago and New York to its global influence today. We'll delve into the iconic tracks, legendary DJs, and the evolution of the genre over the years. Get ready to immerse yourself in the rhythmic waves of electronic bliss.",
              imageUrl: "",
            },
          ],
          conclusion:
            "Whether you're a seasoned Deep House enthusiast or new to the genre, this blog is your gateway to the captivating world of Deep House. Let the music guide you as we dive into the depths of this mesmerizing genre.",
        },
        {
          id: 2,
          url: "Soulful_Sounds",
          date: "15 Nov 2023",
          author: "Euphorea",
          title: "Soulful Sounds: A Celebration of Music with Soul",
          imageUrl:
            "https://travel2next.com/wp-content/uploads/beaches-near-siem-reap-cambodia.jpg",
          introduction: "Join me in celebrating the essence of soulful music.",
          
          paragraphs: [
            {
              id: 1,
              content:
                "Soulful music touches the depths of our hearts and resonates with our emotions.",
              imageUrl: "",
            },
            {
              id: 2,
              content:
                "With heartfelt lyrics, expressive melodies, and powerful vocals, soulful music has the ability to uplift, inspire, and heal.",
              imageUrl: "",
            },
            {
              id: 3,
              content:
                "Join me as we explore the stories behind iconic soul artists and their timeless songs. We'll dive into the influences that shaped the genre, from gospel and R&B to jazz and blues. Discover the artists who pour their emotions into every note, the cultural impact of soulful tunes, and how they continue to inspire generations.",
              imageUrl: "",
            },
            {
              id: 4,
              content:
                "Soulful music is more than just a genre; it's a form of expression that connects people across cultures and generations. Whether you're dancing to a classic soul anthem or finding solace in a heartfelt ballad, soulful sounds have the power to uplift, inspire, and heal. Let the music touch your soul as we celebrate the beauty of music with soul.",
              imageUrl: "",
            },
          ],
          conclusion:
            "Get ready to embark on a soulful journey through the timeless melodies, powerful voices, and heartfelt lyrics that define the genre. Join me as we celebrate the artists who have left an indelible mark on the world of music with their soulful sounds.",
        },
      ];

    const DummyData = [
        {
            id: 1,
            title: "Euphorea Shirt",
            price: 99.00,
            discountedPrice: 79.00,
            image: "https://images.squarespace-cdn.com/content/v1/62ec1fc995263d65f21175de/1673911291595-MFUHQT5N9BBM5FBEYXVJ/germanchocolate17.jpg",
            sale: true,
            category: "clothes",
            popular: true,
            brand: "Euphorea"
        },
        {
            id: 2,
            title: "Euphorea ",
            price: 150.00,
            discountedPrice: 100.00,
            image: "https://gardenandgun.com/wp-content/uploads/2020/07/GG0420_Cover_04-promo-1600x840.png",
            sale: true,
            category: "hoodies",
            popular: true,
            brand: "Euphorea"
        },
    ];

    // useEffect(() => {
    //     const getData = async () => {
    //         try {
    //             const querySnapshot = await getDocs(collection(db, "Store"));
    //             const productsData = querySnapshot.docs.map((doc) => doc.data());
    //             setProducts(productsData);
    //             console.log(productsData);
    //         } catch (error) {
    //             console.error("Error fetching products: ", error);
    //         }
    //     };
    //     getData();
    // }, []);


    const onEditItem = async (product) => {
        try {
            setProducts([...products, newProduct]);
            toast.success("Product edited.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } catch (error) {
            console.error("Error adding item to cart:", error);
        }
    };

    const [newProduct, setNewProduct] = useState({
        id: "",
        title: "",
        description: "",
        price: "",
        rating: "",
        img: "",
        brand: "",
        category: "",
    }); // State for new product form

    // Function to handle search input change
    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Function to handle form input change for adding new product
    const handleNewProductInputChange = (e) => {
        setNewProduct({
            ...newProduct,
            [e.target.name]: e.target.value,
        });
    };

    // Function to handle form submission for adding new product
    const handleNewProductSubmit = (e) => {
        e.preventDefault();
        // Add new product logic here
        // Update the products state with the new product
        setProducts([...products, newProduct]);
        // Clear the form
        setNewProduct({
            id: "",
            title: "",
            description: "",
            price: "",
            rating: "",
            img: "",
            brand: "",
            category: "",
            popular:"",
            sale:"",
        });

        console.log(newProduct)
    };

    // Function to handle deleting a product
    const handleDeleteProduct = (productId) => {
        // Delete product logic here
        // Update the products state by removing the product with the given productId
        const updatedProducts = products.filter(
            (product) => product.id !== productId
        );
        setProducts(updatedProducts);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
// Pagination logic
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedBlogs = blogs.slice(startIndex, endIndex);


    return (
        <div>
            <AdminNavBar />
            <div className="mt-20">

                <div>
                    <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5">
                        <div className="mb-1 w-full">
                            <div className="mb-4">
                                <nav className="flex mb-5" aria-label="Breadcrumb">
                                    <ol className="inline-flex items-center space-x-1 md:space-x-2">
                                        <li className="inline-flex items-center">
                                            <a href="/Admin/Dashboard" className="text-gray-700 hover:text-gray-900 inline-flex items-center">
                                                <svg className="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                                Home
                                            </a>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                                <a href="/Admin/ProductsPage" className="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium">E-commerce</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                                <span className="text-gray-400 ml-1 md:ml-2 text-sm font-medium" aria-current="page">Blogs</span>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                                <h1 className="text-xl sm:text-2xl font-semibold text-gray-900">All Blogs</h1>
                            </div>

                            <div className="block sm:flex items-center md:divide-x md:divide-gray-100">
                                <form className="sm:pr-3 mb-4 sm:mb-0" action="#" method="GET">
                                    <label for="blogs-search" className="sr-only">Search</label>
                                    <div className="mt-1 relative sm:w-64 xl:w-96">
                                        <input type="text" name="email" id="blog-search" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="Search for blogs" />
                                    </div>
                                </form>
                                <div className="flex items-center sm:justify-end w-full">
                                    <div className="hidden md:flex pl-2 space-x-1">
                                        {/* <a href="#" className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                                        </a>
                                        <a href="#" className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                        </a>
                                        <a href="#" className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                        </a>
                                        <a href="#" className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path></svg>
                                        </a> */}
                                    </div>
                                    <button type="button" data-modal-toggle="add-product-modal" onClick={() => {
                                        onEditItem();
                                        setIsEditModalOpen(!isEditModalOpen);
                                    }} className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center rounded-lg text-sm px-3 py-2 text-center sm:ml-auto">
                                        <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                        Add product
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="align-middle inline-block min-w-full">
                            <div className="shadow overflow-hidden">
                                <table className="table-fixed min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th scope="col" className="p-4">
                                                <div className="flex items-center">
                                                    <input id="checkbox-all" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded" />
                                                    <label for="checkbox-all" className="sr-only">checkbox</label>
                                                </div>
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                ID
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                Title
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                Author
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                Date
                                            </th>
                                           
                                        </tr>
                                    </thead>



                                    {paginatedBlogs &&
                                        paginatedBlogs.map((blogs) => {
                                            return (
                                                <tbody className="bg-white divide-y divide-gray-200">

                                                    <tr className="hover:bg-gray-100">
                                                        <td className="p-4 w-4">
                                                            <div className="flex items-center">
                                                                <input id="checkbox" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded" />
                                                                <label for="checkbox" className="sr-only">checkbox</label>
                                                            </div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                                            <div className="text-base font-semibold text-gray-900">{blogs.id}</div>
                                                
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{blogs.title}</td>
                                                        <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{blogs.author}</td>
                                                        <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{blogs.date}</td>
                                                        
                                                        <td className="p-4 whitespace-nowrap space-x-2">
                                                            <button type="button" data-modal-toggle="product-modal" className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center" onClick={() => {
                                                                onEditItem();
                                                                setIsEditModalOpen(!isEditModalOpen);
                                                            }}>
                                                                <svg className="mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
                                                                Edit Blog
                                                            </button>
                                                            <button type="button" data-modal-toggle="delete-product-modal" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center">
                                                                <svg className="mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                                                Delete Blog
                                                            </button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            )
                                        })}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isEditModalOpen && (
                <div className="fixed top-0 right-0 bottom-0 left-0 z-50 bg-black bg-opacity-50 flex justify-center items-center p-4" isOpen={isEditModalOpen}>
                    {/* <EditModal
                        
                        onClose={() => setIsEditModalOpen(false)}
                    /> */}

                    <div
                        className={`overflow-x-hidden overflow-y-auto fixed top-0 bottom-0 left-0 right-0 md:inset-40 z-50 justify-center items-center sm:h-full  "translate-x-0" : "translate-x-full "
                            }`}
                        id="product-modal"
                    >
                        <div className="relative w-full max-w-2xl px-4 h-full md:h-auto"></div>
                        <div className="bg-white rounded-lg shadow relative">
                            <div className="flex items-start justify-between p-5 border-b rounded-t">
                                <h3 className="text-xl font-semibold">Edit product</h3>
                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                    data-modal-toggle="product-modal"
                                    onClick={() => setIsEditModalOpen(false)}
                                >
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </div>

                            <div className="p-6 space-y-6">
                                <form>
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                for="product-name"
                                                className="text-sm font-medium text-gray-900 block mb-2"
                                            >
                                             Title
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="product-name"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                                placeholder="Deep House"

                                                required
                                            />
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                for="author"
                                                className="text-sm font-medium text-gray-900 block mb-2"
                                            >
                                             Author
                                            </label>
                                            <input
                                                type="text"
                                                name="author"
                                                id="author"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                                placeholder="Euphorea"
                                                required
                                            />
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                for="brand"
                                                className="text-sm font-medium text-gray-900 block mb-2"
                                            >
                                                Introduction
                                            </label>
                                            <input
                                                type="text"
                                                name="intro"
                                                id="intro"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                                placeholder="Join me in celebrating the essence of soulful music."
                                                required
                                            />
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                for="price"
                                                className="text-sm font-medium text-gray-900 block mb-2"
                                            >
                                                image
                                            </label>
                                            <input
                                                type="upload"
                                                name="price"
                                                id="price"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                                placeholder="$2300"
                                                required

                                            />
                                        </div>
                                        <div className="col-span-full">
                                            <label
                                                for="product-details"
                                                className="text-sm font-medium text-gray-900 block mb-2"
                                            >
                                                Product Details
                                            </label>
                                            <textarea
                                                id="product-details"
                                                rows="6"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                                                placeholder="e.g. 3.8GHz 8-core 10th-generation Intel Core i7 processor, Turbo Boost up to 5.0GHz, Ram 16 GB DDR4 2300Mhz"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <button onClick={handleNewProductSubmit} type="button" className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center rounded-lg text-sm px-3 py-2 text-center sm:ml-auto mt-5">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            )}
            <Pagination
                totalItems={blogs.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default BlogsPage;
