import React, { useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js"; // Import crypto-js library
import { saveAs } from "file-saver";

import { collection, serverTimestamp, addDoc } from "firebase/firestore";
import { db } from "../../Database/Firebase";

function CheckoutNotify() {
  const [showTable, setShowTable] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);



  const testingMode = true;
  const pfHost = testingMode ? "sandbox.payfast.co.za" : "www.payfast.co.za";

  const pfData = {};
  let pfParamString = "";
  const passPhrase = "bak1c5CaK3i5G00d";

  for (let key in pfData) {
    if (pfData.hasOwnProperty(key) && key !== "signature") {
      pfParamString += `${key}=${encodeURIComponent(pfData[key].trim()).replace(
        /%20/g,
        "+"
      )}&`;
    }
  }

  // Remove last ampersand
  pfParamString = pfParamString.slice(0, -1);

  const pfValidSignature = (pfData, pfParamString, pfPassphrase = null) => {
    let tempParamString = "";
    if (pfPassphrase !== null) {
      pfParamString += `&passphrase=${encodeURIComponent(
        pfPassphrase.trim()
      ).replace(/%20/g, "+")}`;
    }

    const signature = CryptoJS.MD5(pfParamString).toString();

    return pfData["signature"] === signature;
  };

  async function ipLookup(domain) {
    try {
      const response = await axios.get(`https://ipinfo.io/${domain}/json`);
      const data = response.data;

      // Extract the IP address or other relevant information from the response
      const ipAddress = data.ip;

      return ipAddress;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      throw error;
    }
  }

  const pfValidIP = async () => {
    const validHosts = [
      "www.payfast.co.za",
      "sandbox.payfast.co.za",
      "w1w.payfast.co.za",
      "w2w.payfast.co.za",
    ];

    let validIps = [];
    const pfIp = window.location.hostname;

    try {
      for (let key in validHosts) {
        const ips = await ipLookup(validHosts[key]);
        validIps = [...validIps, ...ips];
      }
    } catch (err) {
      console.error(err);
    }

    const uniqueIps = [...new Set(validIps)];

    if (uniqueIps.includes(pfIp)) {
      return true;
    }
    return false;
  };

  const pfValidServerConfirmation = async (pfHost, pfParamString) => {
    const result = await axios
      .post(`https://${pfHost}/eng/query/validate`, pfParamString)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
    return result === "VALID";
  };

  const pfValidPaymentData = (cartTotal, pfData) => {
    return (
      Math.abs(parseFloat(cartTotal) - parseFloat(pfData["amount_gross"])) <=
      0.01
    );
  };

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      const check1 = pfValidSignature(pfData, pfParamString, passPhrase);
      const check2 = await pfValidIP();
      const check3 = await pfValidServerConfirmation(pfHost, pfParamString);

      if (check1 && check2 && check3) {
        // All checks have passed, save the order to the database
        try {
          const orderData = {
            orderNumber: pfData.m_payment_id,
            items: [
              {
                name: pfData.item_name,
                amount_gross: pfData.amount_gross,
                email: pfData.email_address,
                payment_status: pfData.payment_status,
                firstname: pfData.name_first,
                lastname: pfData.name_last,
                address: pfData.custom_str1,
                city: pfData.custom_str2,
                zipcode: pfData.custom_str3,
              },
            ],
            totalPrice: pfData.amount_gross,
            timestamp: serverTimestamp(),
          };

          const orderRef = await addDoc(collection(db, "orders"), orderData);
          console.log("Order saved to database:", orderRef.id);

          // Save order information to a text file
          const orderInfoText = JSON.stringify(orderData, null, 2);
          const blob = new Blob([orderInfoText], {
            type: "text/plain;charset=utf-8",
          });
          saveAs(blob, "order_info.txt");
        } catch (error) {
          console.error("Error saving order:", error);
        }
      } else {
        console.log("Payment checks failed");
        // Some checks have failed, handle accordingly
        // You can add logic here for handling a failed payment
      }
    };

    handlePaymentSuccess();

    
  }, [pfData, pfParamString, passPhrase, pfValidIP, pfHost]);

  

  return (
    <div>
    
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Transaction ID</th>
                <th className="py-2 px-4 border-b">Amount</th>
                {/* Add more headers based on your transaction data */}
              </tr>
            </thead>
            <tbody>
              {transactionHistory.map((transaction) => (
                <tr key={transaction.transaction_id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{transaction.transaction_id}</td>
                  <td className="py-2 px-4 border-b">{transaction.amount}</td>
                  {/* Add more cells based on your transaction data */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      
        
        <div className="bg-gray-100 h-screen">
          <div className="bg-white p-6 md:mx-auto">
            <svg
              viewBox="0 0 24 24"
              className="text-green-600 w-16 h-16 mx-auto my-6"
            >
              <path
                fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
            <div className="text-center">
              <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                Payment Done!
              </h3>
              <p className="text-gray-600 my-2">
                Thank you for completing your secure online payment.
              </p>
              <p> Have a great day! </p>
              <div className="py-10 text-center">
                <a
                  href="/"
                  className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
                >
                  GO BACK
                </a>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  );
}

export default CheckoutNotify;
