import React, { useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { doc, addDoc, updateDoc, arrayUnion, deleteDoc } from "firebase/firestore";
import { db } from "../../Database/Firebase";
import AdminNavBar from './NavBar';
import { useNavigate } from 'react-router-dom';

const EditProductPage = ({ product, title, description, brand, category, price }) => {
  const [productdata, setProductdata] = useState(product);

  const navigate = useNavigate();

  const HandleBack = () => {
          // navigate("/Admin/ProductsPage");
          navigate(-1);
      };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    title: '',
    category: '',
    brand: '',
    price: 0,
    description: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {

      console.log(formData)
    } catch (error) {
      console.error('Error adding product to Firestore:', error);
      
    }
  };

  return (
    <div>
      <AdminNavBar />

      <div className={`overflow-x-hidden overflow-y-auto mt-20 z-50 justify-center items-center "translate-x-0" : "translate-x-full "
                }`}>
        <div className="bg-white rounded-lg shadow relative">
          <div className="flex items-start justify-between p-5 border-b rounded-t">
            <h3 className="text-xl font-semibold">Add new product</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-toggle="product-modal"
              onClick={HandleBack}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div className="p-6 space-y-6">
            <form>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    for="product-name"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="product-name"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Euphorea Hat"
                    onChange={handleInputChange}
          
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    for="category"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Category
                  </label>
                  <input
                    type="text"
                    name="category"
                    id="category"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Headwear"
                    value={category}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    for="sale"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Sale
                  </label>

                  <select name="category"
                    id="sale"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-60 p-2.5 overflow-hidden"
                    value={category}
                    onChange={handleInputChange}
                    >
                    <option value="" disabled>
                      Set Sale
                    </option>
                    {[
                      "True",
                      "False",
                    ].map((sale) => (
                      <option key={sale} value={sale} >
                        {sale}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    for="brand"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Brand
                  </label>
                  <input
                    type="text"
                    name="brand"
                    id="brand"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Euphorea"
                    value={brand}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    for="price"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Price
                  </label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="R1100"
                    required
                    value={price}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-span-full">
                  <label
                    for="product-details"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Product Details
                  </label>
                  <textarea
                    id="product-details"
                    name='description'
                    type="text"
                    rows="6"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                    placeholder="e.g. 3.8GHz 8-core 10th-generation Intel Core i7 processor, Turbo Boost up to 5.0GHz, Ram 16 GB DDR4 2300Mhz"
                    value={description}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
              <button type="button" onClick={handleSubmit} className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center rounded-lg text-sm px-3 py-2 text-center sm:ml-auto mt-5">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductPage;