import React from 'react'

import AdminNavBar from '../Components/NavBar'
import Profile from './Profile'


const Settings = () => {

    return (
        <div>
            <Profile />
        </div>
    )
}

export default Settings