import React from "react";
import NavBar from "../Components/NavBar";
import Image1 from "../Assets/Images/2024/2024 March/Latest gig 3.jpg";
import Image2 from "../Assets/Images/2024/2024 March/Latest gig 2.jpg";
import Image3 from "../Assets/Images/2024/2024 March/Yfm.jpg";
import Image4 from "../Assets/Images/Gig.jpg";
import Image5 from "../Assets/Images/2024/2024 Jan/Event01.jpg";
import Image6 from "../Assets/Images/2024/2024 Jan/Event02.jpg";
import Image7 from "../Assets/Images/Event-1.jpg";
import Image8 from "../Assets/Images/Event-2.jpg";
import Image9 from "../Assets/Images/2024/2024 April/Event-1.jpg";
import Image10 from "../Assets/Images/2024/2024 April/Event-2.jpg";
import Image11 from "../Assets/Images/2024/2024 April/Event-3.jpg";
import Image12 from "../Assets/Images/2024/2024 April/Event-4.jpg";
import Image13 from "../Assets/Images/2024/2024 April/Event-.jpg";
import { Carousel, IconButton, Typography } from "@material-tailwind/react";

const galleryData = [
  {
    id: 1,
    src: Image1,
    alt: "Latest Gig 1",
  },
  {
    id: 2,
    src: Image2,
    alt: "Latest Gig 2",
  },
  {
    id: 3,
    src: Image3,
    alt: "Event 1",
  },
  {
    id: 4,
    src: Image4,
    alt: "Event 4",
  },
  {
    id: 5,
    src: Image5,
    alt: "Event 5",
  },
  {
    id: 6,
    src: Image6,
    alt: "Event 6",
  },
  {
    id: 7,
    src: Image7,
    alt: "Event 7",
  },
  {
    id: 9,
    src: Image9,
    alt: "Event 8",
  },

  {
    id: 10,
    src: Image10,
    alt: "Event 8",
  },

  {
    id: 11,
    src: Image11,
    alt: "Event 8",
  },

  {
    id: 12,
    src: Image12,
    alt: "Event 8",
  },

  {
    id: 13,
    src: Image13,
    alt: "Event 8",
  },
  

];

export function GalleryWithCarousel() {
  return (
    <Carousel
      transition={{ duration: 3 }}
      loop={true}
      autoplay={true}
      className="rounded-xl  mb-26"
      prevArrow={({ handlePrev }) => (
        <IconButton
          variant="text"
          color="black"
          size="lg"
          onClick={handlePrev}
          className="!absolute top-2/4 left-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </IconButton>
      )}
      nextArrow={({ handleNext }) => (
        <IconButton
          variant="text"
          color="black"
          size="lg"
          onClick={handleNext}
          className="!absolute top-2/4 !right-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </IconButton>
      )}
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-black" : "w-4 bg-blue-gray-500"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
    >
      {galleryData.map((item) => (
        <img
          key={item.id} // Use the id for unique key
          src={item.src}
          alt={item.alt}
          className="h-[450px] w-screen object-contain object-center"
        />
      ))}
    </Carousel>
  );
}

export function Gallery() {
  return (
    <div>
      <NavBar />
      <div className="container mx-auto mt-32">
        <Typography
          variant="h3"
          color="blue-gray"
          className="text-center mb-5 "
        >
          My Gallery
        </Typography>

        <GalleryWithCarousel />

        <div class="grid mx-16 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 gap-4 mt-16 ">
          {galleryData.map((item) => (
            <img
              key={item.id} // Use the id for unique key
              src={item.src}
              alt={item.alt}
              className="h-auto max-w-full rounded-lg"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
