import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../Database/Firebase';
import { getDocs, collection } from 'firebase/firestore';
import Pagination from './Pagination';
import AdminDashboard from '../Pages/AdminDashboard';
import AdminNavBar from './NavBar';

const Clients = [
    {
        "id": 1,
        "name": "Neil Sims",
        "avatar": "neil-sims.png",
        "numbers": "0835389343",
        "email": "neil.sims@windster.com",
        "position": "Front-end developer",
        "country": "United States",
        "status": "Active"

      },
      {
        "id": 2,
        "name": "Roberta Casas",
        "avatar": "roberta-casas.png",
        "numbers": "0637357302",
        "email": "roberta.casas@windster.com",
        "position": "Designer",
        "country": "Spain",
        "status": "Active"
        
      },
      {
        "id": 3,
        "name": "Michael Gough",
        "avatar": "michael-gough.png",
        "numbers": "0823674972",
        "email": "michael.gough@windster.com",
        "position": "React developer",
        "country": "United Kingdom",
        "status": "Active"
      },
      {
        "id": 4,
        "name": "Jese Leos",
        "avatar": "jese-leos.png",
        "numbers": "0832653972",
        "email": "jese.leos@windster.com",
        "position": "Marketing",
        "country": "United States",
        "status": "Active"
      },
      {
        "id": 5,
        "name": "Bonnie Green",
        "avatar": "bonnie-green.png",
        "email": "bonnie.green@windster.com",
        "position": "UI/UX Engineer",
        "country": "Australia",
        "numbers": "0835389343",
        "status": "Offline"
        
      },
      {
        "id": 6,
        "name": "Thomas Lean",
        "avatar": "thomas-lean.png",
        "email": "thomas.lean@windster.com",
        "position": "Vue developer",
        "country": "Germany",
        "numbers": "0835389343",
        "status": "Active"
      },
      {
        "id": 7,
        "name": "Helene Engels",
        "avatar": "helene-engels.png",
        "email": "helene.engels@windster.com",
        "position": "Product owner",
        "country": "Canada",
        "numbers": "0835389343",
        "status": "Active"
      },
      {
        "id": 8,
        "name": "Lana Byrd",
        "avatar": "lana-byrd.png",
        "email": "lana.byrd@windster.com",
        "position": "Designer",
        "numbers": "0835389343",
        "country": "United States",
        "status": "Active"
      },
      {
        "id": 9,
        "name": "Leslie Livingston",
        "avatar": "leslie-livingston.png",
        "email": "leslie.livingston@windster.com",
        "position": "Web developer",
        "numbers": "0835389343",
        "country": "France",
        "status": "Offline"
      },
      {
        "id": 10,
        "name": "Robert Brown",
        "avatar": "robert-brown.png",
        "email": "robert.brown@windster.com",
        "position": "Laravel developer",
        "numbers": "0835389343",
        "country": "Russia",
        "status": "Active"
      },
      {
        "id": 11,
        "name": "Neil Sims",
        "avatar": "neil-sims.png",
        "email": "neil.sims@windster.com",
        "numbers": "0835389343",
        "position": "Front-end developer",
        "country": "United States",
        "status": "Active"
      },
      {
        "id": 12,
        "name": "Roberta Casas",
        "avatar": "roberta-casas.png",
        "email": "roberta.casas@windster.com",
        "numbers": "0835389343",
        "position": "Designer",
        "country": "Spain",
        "status": "Active"
      },
      {
        "id": 13,
        "name": "Michael Gough",
        "avatar": "michael-gough.png",
        "email": "michael.gough@windster.com",
        "numbers": "0835389343",
        "position": "React developer",
        "country": "United Kingdom",
        "status": "Active"
      },
      {
        "id": 14,
        "name": "Jese Leos",
        "avatar": "jese-leos.png",
        "email": "jese.leos@windster.com",
        "numbers": "0835389343",
        "position": "Marketing",
        "country": "United States",
        "status": "Active"
      },
      {
        "id": 15,
        "name": "Holly Green",
        "avatar": "bonnie-green.png",
        "email": "bonnie.green@windster.com",
        "numbers": "0835389343",
        "position": "UI/UX Engineer",
        "country": "Australia",
        "status": "Offline"
      },
      {
        "id": 16,
        "name": "Bloek Me",
        "avatar": "thomas-lean.png",
        "email": "thomas.lean@windster.com",
        "numbers": "0633121733",
        "country": "Germany",
        "status": "Active"
      },
      {
        "id": 17,
        "name": "Helen Zila",
        "avatar": "helene-engels.png",
        "email": "helene.engels@windster.com",
        "numbers": "0835389343",
        "position": "Product owner",
        "country": "Canada",
        "status": "Active"
      },
      {
        "id": 18,
        "name": "Suka Mahn",
        "avatar": "lana-byrd.png",
        "email": "lana.byrd@windster.com",
        "numbers": "0835389343",
        "position": "Designer",
        "country": "United States",
        "status": "Active"
      },
      {
        "id": 19,
        "name": "Lestan Shaw",
        "avatar": "leslie-livingston.png",
        "email": "leslie.livingston@windster.com",
        "numbers": "0835389343",
        "position": "Web developer",
        "country": "France",
        "status": "Offline"
      },
      {
        "id": 20,
        "name": "Tumelo Thinane ",
        "avatar": "robert-brown.png",
        "email": "robert.brown@windster.com",
        "numbers": "0835389343",
        "position": "Laravel developer",
        "country": "Russia",
        "status": "Active"
      }

];


const ViewAllEmaills = () => {
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust as needed
    


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'users', 'emails'));
                const transactionsData = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    const date = data.date.toDate();
                    return { ...data, date };
                });
                setTransactions(transactionsData);
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };

        fetchData();
    }, []);

    

    // Pagination logic
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedUsers = Clients.slice(startIndex, endIndex);

   
    return (
        <div>
          <AdminNavBar/>
            <div className='bg-white shadow-md rounded-lg mb-4 p-4 sm:p-6 h-full mt-20'>
                <div className="mb-4 flex items-center justify-between">
                    <div>
                        <h3 className="text-xl font-bold text-gray-900 mb-2">Email List</h3>
                        <span className="text-base font-normal text-gray-500">This is a list of all emails</span>
                    </div>
                    <div className="flex-shrink-0">
                        <Link to="/Admin/Dashboard" className="text-sm font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2">Go back</Link>
                    </div>
                </div>

                <div className="flex flex-col mt-8">
                    <div className="overflow-x-auto rounded-lg">
                        <div className="align-middle inline-block min-w-full">
                            <div className="shadow overflow-hidden sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                id
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                email
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Name
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Numbers
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Status
                                            </th>

                                        </tr>
                                    </thead>

                                    <tbody className="bg-white">
                                        { paginatedUsers && paginatedUsers.map((user) => (
                                            <tr >
                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                                    {user.id}
                                                    <span className="font-semibold"></span>
                                                </td>

                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                                    {user.email}
                                                    <span className="font-semibold"></span>
                                                </td>

                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                                    {user.name}
                                                    <span className="font-semibold"></span>
                                                </td>

                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                                    {user.numbers}
                                                    <span className="font-semibold"></span>
                                                </td>

                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                                    {user.status}
                                                    <span className="font-semibold"></span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Pagination component */}
            <Pagination
                totalItems={Clients.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default ViewAllEmaills;
