import React from 'react'
import AdminNavBar from '../Components/NavBar'
import Viewtransactions from '../Components/transactions'
import Customers from '../Components/Customers'
import Accuisitions from '../Components/Accuisitions'
import Stats from '../Components/Stats'


const AdminDashboard = () => {
    return (
        <div>
            <AdminNavBar />
            <div>

                <div className="grid grid-cols-1 2xl:grid-cols-2 xl:gap-4 my-4 mt-20">
                    <Stats />
                </div>
                
                <div className="pt-6 px-4 mt-20">

                    <div className="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">

                        <Viewtransactions />

                    </div>
                    <div className="grid grid-cols-1 2xl:grid-cols-2 xl:gap-4 my-4">
                        <Customers />
                        <Accuisitions />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard