import React, { useState } from "react";
import paxi from "../../../Assets/Images/paxi.png"
function ShippingOptions({ onShippingOptionSelect }) {
  const [selectedOption, setSelectedOption] = useState("radio_1");
  const shippingOptions = [
    { id: "radio_1", label: "Paxi", deliveryTime: "7-9 business days", price: 60, image:paxi },
    { id: "radio_2", label: "Paxi Delivery", deliveryTime: "3-5 business days", price: 100, image:paxi },
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
    const selectedShippingOption = shippingOptions.find(option => option.id === event.target.id);
    onShippingOptionSelect(selectedShippingOption);
  };

  return (
    <div>
      <p className="mt-8 text-lg font-medium">Shipping Methods</p>
      <form className="mt-5 grid gap-6">
        {shippingOptions.map((option) => (
          <div key={option.id} className="relative">
            <input
              className="peer hidden"
              id={option.id}
              type="radio"
              name="radio"
              checked={selectedOption === option.id}
              onChange={handleOptionChange}
            />
            <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
            <label
              className={`peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4 ${selectedOption === option.id ? "border-gray-700" : ""}`}
              htmlFor={option.id}
            >
              <img className="w-14 object-contain" src={option.image} alt="" />
              <div className="ml-5">
                <span className="mt-2 font-semibold">{option.label}</span>
                <p className="text-slate-500 text-sm leading-6">Delivery: {option.deliveryTime}</p>
                <p className="text-slate-500 text-sm leading-6">Price: R{option.price.toFixed(2)}</p>
              </div>
            </label>
          </div>
        ))}
      </form>
    </div>
  );
}

export default ShippingOptions;