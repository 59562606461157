import React, { useState, useEffect } from "react";
import AdminNavBar from "../Components/NavBar";
import Pagination from "../Components/Pagination";
import { collection, getDocs } from "firebase/firestore";
import { doc, addDoc, updateDoc, arrayUnion, deleteDoc } from "firebase/firestore";
import { db } from "../../Database/Firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const DummyData = [
    {
        id: 1,
        title: "Euphorea Shirt",
        price: 99.00,
        discountedPrice: 79.00,
        image: "https://images.squarespace-cdn.com/content/v1/62ec1fc995263d65f21175de/1673911291595-MFUHQT5N9BBM5FBEYXVJ/germanchocolate17.jpg",
        sale: true,
        category: "shirts",
        popular: true,
        brand: "Euphorea"
    },
    {
        id: 2,
        title: "Euphorea Hoodie",
        price: 150.00,
        discountedPrice: 100.00,
        image: "https://gardenandgun.com/wp-content/uploads/2020/07/GG0420_Cover_04-promo-1600x840.png",
        sale: true,
        category: "hoodies",
        popular: true,
        brand: "Euphorea"
    },
];

const ProductsPage = ({ product, }) => {
    const [products, setProducts] = useState([]);
    const [onsale, setOnsale] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust as needed
    const navigate = useNavigate();

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const EditItem = (product) => {
        setSelectedProduct(product);

        navigate(`/Admin/EditProductsPage/${product.title}`, {
            title: product.title,
        });

        // navigate("/Admin/NewProductsPage", {
        //     title: product.title,
        // });
    };

    const AddNewItem = () => {
        navigate(`/Admin/NewProductsPage`);
    };



    useEffect(() => {
        const getData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "Store"));
                const productsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setProducts(productsData);

                // setProducts(DummyData);
                console.log(products);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };
        getData();
    }, []);

    const onEditItem = (productId) => {
        const selected = products.find((product) => product.id === productId);
        setSelectedProduct(selected);
        setIsEditModalOpen(true);
        
    };

    const handleDeleteProduct = async (productId) => {
        try {
            // Delete product from Firebase
            await deleteDoc(doc(db, "Store", product));
            toast.success("Product deleted successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            // Update state to reflect the deletion
            const updatedProducts = products.filter((product) => product.id !== productId);
            setProducts(updatedProducts);
        } catch (error) {
            console.error("Error deleting product: ", error);
            toast.error("Failed to delete product. Please try again.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };

    // Pagination logic
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedProducts = products.slice(startIndex, endIndex);
    


    return (
        <div>
            <AdminNavBar />
            <ToastContainer />
            {selectedProduct ? (
                product = { selectedProduct }
            ) : (


                <div className="mt-20">

                    <div>
                        <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 ">
                            <div className="mb-1 w-full">
                                <div className="mb-4">
                                    <nav className="flex mb-5" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 md:space-x-2">
                                            <li className="inline-flex items-center">
                                                <a href="/Admin/Dashboard" className="text-gray-700 hover:text-gray-900 inline-flex items-center">
                                                    <svg className="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                                    Home
                                                </a>
                                            </li>
                                            <li>
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <a href="/Admin/ProductsPage" className="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium">E-commerce</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <span className="text-gray-400 ml-1 md:ml-2 text-sm font-medium" aria-current="page">Products</span>
                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                    <h1 className="text-xl sm:text-2xl font-semibold text-gray-900">All products</h1>
                                </div>

                                <div className="block sm:flex items-center md:divide-x md:divide-gray-100">
                                    <form className="sm:pr-3 mb-4 sm:mb-0" action="#" method="GET">
                                        <label htmlFor="products-search" className="sr-only">Search</label>
                                        <div className="mt-1 relative sm:w-64 xl:w-96">
                                            <input type="text" name="email" id="products-search" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="Search for products" />
                                        </div>
                                    </form>
                                    <div className="flex items-center sm:justify-end w-full">
                                        <div className="hidden md:flex pl-2 space-x-1">
                                            {/* <a href="#" className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                                            </a>
                                            <a href="#" className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                            </a>
                                            <a href="#" className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                            </a>
                                            <a href="#" className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path></svg>
                                            </a> */}
                                        </div>
                                        <button type="button" data-modal-toggle="add-product-modal"
                                            onClick={AddNewItem}
                                            className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center rounded-lg text-sm px-3 py-2 text-center sm:ml-auto">
                                            <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                            Add product
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <div className="overflow-x-auto">
                            <div className="align-middle inline-block min-w-full">
                                <div className="shadow overflow-hidden">
                                    <table className="table-fixed min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th scope="col" className="p-4">
                                                    <div className="flex items-center">
                                                        <input id="checkbox-all" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded" />
                                                        <label htmlFor="checkbox-all" className="sr-only">checkbox</label>
                                                    </div>
                                                </th>
                                                <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                    ID
                                                </th>
                                                <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                    title
                                                </th>
                                                <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                    Brand
                                                </th>

                                                <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                    Price
                                                </th>
                                                <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                    Discount Price
                                                </th>
                                                <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                    popular
                                                </th>
                                                <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                                    Sale
                                                </th>
                                                <th scope="col" className="p-4">
                                                </th>
                                            </tr>
                                        </thead>

                                        {paginatedProducts.map((product) => (

                                            <tbody className="bg-white divide-y divide-gray-200">

                                                <tr className="hover:bg-gray-100">
                                                    <td className="p-4 w-4">
                                                        <div className="flex items-center">
                                                            <input id="checkbox" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded" />
                                                            <label htmlFor="checkbox" className="sr-only">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{product.id}</td>
                                                    <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                                        <div className="text-base font-semibold text-gray-900">{product.title}</div>
                                                        <div className="text-sm font-normal text-gray-500">{product.category}</div>
                                                    </td>
                                                    <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{product.brand}</td>

                                                    <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">R {product.price}</td>
                                                    {product.sale && (
                                                        <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">R {product.discountedPrice}</td>
                                                    )}
                                                    

                                                    {product.popular &&(
                                                        <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">popular</td>
                                                    )}

                                                    {product.sale && (
                                                        <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">OnSale</td>
                                                    )}


                                                    <td className="p-4 whitespace-nowrap space-x-2">
                                                        <button type="button" data-modal-toggle="product-modal" className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center" key={product.id}
                                                            onClick={() => EditItem(product)}>
                                                            <svg className="mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd"></path></svg>
                                                            Edit item
                                                        </button>
                                                        <button type="button" data-modal-toggle="delete-product-modal" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center"
                                                            onClick={handleDeleteProduct}
                                                        >
                                                            <svg className="mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                                                            Delete item
                                                        </button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Pagination
                totalItems={products.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};



export default ProductsPage;