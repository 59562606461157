import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../Database/Firebase';
import { getDocs, collection, updateDoc, doc } from 'firebase/firestore';
import Pagination from '../Components/Pagination';
import AdminNavBar from '../Components/NavBar';

const OrderModal = ({ order, onClose }) => {

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [selectedOrderForConfirmation, setSelectedOrderForConfirmation] = useState(null);

    const handleConfirmOrder = async () => {
        try {
          await updateDoc(doc(db, "orders", selectedOrderForConfirmation.id), {
            status: "complete",
          });
          setSelectedOrderForConfirmation(null);
          setIsConfirmationModalOpen(false);
        //   getData(); // Refresh the order list
        } catch (error) {
          console.error("Error confirming order:", error);
          // Handle error gracefully, e.g., display an error message to the user
        }
      };

      const handleCloseConfirmationModal = () => {
        setSelectedOrderForConfirmation(null);
        setIsConfirmationModalOpen(false);
      };

    return (
        <div className="modal" style={{ display: isConfirmationModalOpen ? 'block' : 'none' }}>
            <div className="modal-content">
                <h2>Confirm Order</h2>
                <p>Are you sure you want to confirm order {selectedOrderForConfirmation.invoice}?</p>
                <div className="modal-actions">
                    <button onClick={handleCloseConfirmationModal}>Cancel</button>
                    <button onClick={handleConfirmOrder}>Confirm</button>
                </div>
            </div>
        </div>


    );
};

const ViewOrders = () => {
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust as needed
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    

    const [showModel, setShowModel] = useState(false);

    const handleViewOrder = (orderId) => {
        setSelectedOrderId(orderId);
        setShowModel(true);
    };

    const handleCloseModel = () => {
        setSelectedOrderId(null);
        setShowModel(false);
    };


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleStatusUpdate = async (orderId) => {
        try {
            await updateDoc(doc(db, "orders", orderId), {
                status: "complete",
            });
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order.id === orderId ? { ...order, status: "complete" } : order
                )
            );
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    useEffect(() => {
        const getData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "orders"));
                const OrderData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setOrders(OrderData);
                console.log(OrderData)
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        if (selectedOrderId) {
            const fetchOrder = async () => {
                const docRef = doc(db, "orders", selectedOrderId);
                const snapshot = await getDocs(docRef);
                const orderData = snapshot.docs[0].data();
                setSelectedOrder(orderData);
            };
            fetchOrder();
        } else {
            setSelectedOrder(null);
        }
    }, [selectedOrderId]);



    // Pagination logic
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedUsers = orders.slice(startIndex, endIndex);


    return (
        <div>
            <AdminNavBar />
            <div className='bg-white shadow-md rounded-lg mb-4 p-4 sm:p-6 h-full mt-20'>
                <div className="mb-4 flex items-center justify-between">
                    <div>
                        <h3 className="text-xl font-bold text-gray-900 mb-2">Order List</h3>
                        <span className="text-base font-normal text-gray-500">This is a list of all Orders</span>
                    </div>
                    <div className="flex-shrink-0">
                        <Link to="/Admin/Dashboard" className="text-sm font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2">Go back</Link>
                    </div>
                </div>

                <div className="flex flex-col mt-8">
                    <div className="overflow-x-auto rounded-lg">
                        <div className="align-middle inline-block min-w-full">
                            <div className="shadow overflow-hidden sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Invoice
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                email
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Name
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Numbers
                                            </th>
                                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Status
                                            </th>
                                            {/* <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                items
                                            </th> */}
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white">
                                        {paginatedUsers && paginatedUsers.map((user) => (
                                            <tr >
                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">

                                                    <span className="font-semibold">{user.orderNumber}</span>
                                                </td>

                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">

                                                    <span className="font-semibold">{user.email}</span>
                                                </td>

                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">

                                                    <span className="font-semibold">{user.Firstname}</span>
                                                </td>

                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">

                                                    <span className="font-semibold">{user.phoneNumber}</span>
                                                </td>
                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                                    <span
                                                        className={`font-semibold ${user.status === "pending" ? "bg-yellow-500 text-white inline-flex items-center rounded-lg text-sm px-3 py-2 text-center sm:ml-auto" : "bg-green-500 text-white inline-flex items-center rounded-lg text-sm px-3 py-2 text-center sm:ml-auto"
                                                            }`}
                                                    >
                                                        {user.status}
                                                    </span>
                                                </td>
                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">


                                                    <span className="font-semibold">{user.item}</span>
                                                </td>
                                                <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                                    <button
                                                        className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium inline-flex items-center rounded-lg text-sm px-3 py-2 text-center sm:ml-auto"
                                                        onClick={() => handleViewOrder(user.orderId)}
                                                    >
                                                        View Order
                                                    </button>
                                                </td>
                                                <td >
                                                    {user.status === "pending" && ( // Conditionally render button only if status is pending
                                                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center rounded-lg text-sm px-3 py-2 text-center sm:ml-auto" onClick={() => handleStatusUpdate(user.id)}>Confirm Order</button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {showModel && (
                <OrderModal order={selectedOrder} onClose={handleCloseModel} />
            )}

            {/* Pagination component */}
            <Pagination
                totalItems={orders.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default ViewOrders;
