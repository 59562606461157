import React from 'react';

const MaintenancePage = () => {
  const contactEmail = 'info.euphorea@gmail.com'; 

  return (
    <div className="flex items-center justify-center h-screen">
    <div className="flex flex-col items-center justify-center max-w-lg">
      <div className="mb-4">
        <h1 className="text-5xl font-extrabold text-blue-600">503</h1>
      </div>
      <h3 className="mb-3 text-2xl font-bold text-center text-gray-700">
        Website is Temporarily down for maintenance
        We’ll be back soon!
      </h3>
      <p className="text-sm text-center text-gray-600">
        Sorry for the inconvenience but we’re performing some maintenance at the moment.
        If you need to, you can always Contact us: {' '}  
        <a
          className="text-blue-600 hover:underline"
          href={`mailto:${contactEmail}`}
        >
           {contactEmail}
        </a>
        , otherwise, we’ll be back online shortly!
        — Euphorea
      </p>
    </div>
  </div>
  );
};

export default MaintenancePage;
