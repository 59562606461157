import React from 'react'

const Customers = () => {

    const Clients = [
        {
            name: "Everest",
            email: "everest23@gmail.com",
        },

        {
            name: "Meldo",
            email: "shadesmeldo@gmail.com",
        },

        {
            name: "Sletja",
            email: "sletja01@gmail.com",
        },

        {
            name: "Tumelo",
            email: "tumelothinane13@gmail.com",
        },

    ];

    return (
        <div className='bg-white shadow-md rounded-lg mb-4 p-4 sm:p-6 h-full '>
            <div className="mb-4 flex items-center justify-between">
                <div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">Email List</h3>
                    <span className="text-base font-normal text-gray-500">This is a list of latest Clients</span>
                </div>
                <div className="flex-shrink-0">
                    <a href="/Admin/ViewAllEmails" className="text-sm font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2">View all</a>
                </div>
            </div>

            <div className="flex flex-col mt-8">
                <div className="overflow-x-auto rounded-lg">
                    <div className="align-middle inline-block min-w-full">
                        <div className="shadow overflow-hidden sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Name
                                        </th>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Client Email
                                        </th>
                                        
                                    </tr>
                                </thead>

                                <tbody className="bg-white">
                                    {Clients &&
                                        Clients.map((clients)  => {

                                            return (
                                                <tr >
                                                    <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                                        <span className="font-semibold">{clients.name}</span>
                                                    </td>

                                                    <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                                        <span className="font-semibold">{clients.email}</span>
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Customers