import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { db } from "../../Database/Firebase";
import { collection, serverTimestamp, addDoc } from "firebase/firestore";
import OrderSummary from "./OrderSummary/OrderSummary";
import ShippingOptions from "./ShippingOptions/ShippingOptions";
import CheckoutProgression from "./CheckoutProgression/CheckoutProgression";
import ShippingInfo from "./ShippingInfo/ShippingInfo";
import axios from "axios";

function Checkout() {
  // const navigate = useNavigate();
  const location = useLocation();
  const [name_first, setFirstName] = useState("");
  const [name_last, setLastName] = useState("");
  const [streetaddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [zip, setZip] = useState("");
  const [email_address, setEmail] = useState("");
  const [cell_number, setCellNumber] = useState("");
  const cart = location.state.cart;
  const [item_title, setItemTitle] = useState(""); // Add this line for item_name

  const [currentStep] = React.useState(2);

  const [selectedShippingOption, setSelectedShippingOption] = useState(null);

  const handleShippingOptionSelect = (option) => {
    setSelectedShippingOption(option);
    console.log(selectedShippingOption)
  };

  // Function to calculate the total price of items in the cart
  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price, 0);
  };

  const generateUniqueOrderNumber = () => {
    const uniqueId = uuidv4(); // Generate a unique ID using UUID
    const truncatedId = uniqueId.replace(/-/g, '').substring(0, 4);
    return `INV-${truncatedId}`;
  };

  // const generateSignature = (data, passPhrase = null) => {
  //   // Create parameter string
  //   let pfOutput = "";
  //   for (let key in data) {
  //     if (data.hasOwnProperty(key)) {
  //       if (data[key] !== "") {
  //         pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(
  //           /%20/g,
  //           "+"
  //         )}&`;
  //       }
  //     }
  //   }

  //   // Remove last ampersand
  //   let getString = pfOutput.slice(0, -1);
  //   if (passPhrase !== null) {
  //     getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(
  //       /%20/g,
  //       "+"
  //     )}`;
  //   }

  //   return crypto.createHash("md5").update(getString).digest("hex");
  // };

  const generateSignature = (data, passPhrase = null) => {

    let pfOutput = "";
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== "") {
          pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(/%20/g, "+")}&`
        }
      }
    }

    // Remove last ampersand
    let getString = pfOutput.slice(0, -1);
    if (passPhrase !== null) {
      getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(/%20/g, "+")}`;
    }

    return crypto.createHash("md5").update(getString).digest("hex");
  };








  const saveOrderToDatabase = async (userEmail) => {
    try {
      const orderData = {
        
        
      };

      // // Use the user's email as the document name
      // const orderRef = await addDoc(
      //   collection(db, "orders"),
      //   orderData
        
      // );

      const orderRef = await addDoc(collection(db, "orders"), {
        orderNumber: generateUniqueOrderNumber(),
        items: cart,
        totalPrice: calculateTotalPrice() + (selectedShippingOption ? selectedShippingOption.price : 0),
        timestamp: serverTimestamp(),
        status: "pending",
        Firstname: name_first,
        lastname:  name_last,
        email: email_address,
        phoneNumber: cell_number,
        city: city,
        zip: zip,
        province: province,
        streetaddress: streetaddress,
      });
      
      console.log("Order saved to database:", orderRef.id);
    } catch (error) {
      console.error("Error saving order to database:", error);
    }
  };


  const generatePaymentIdentifier = async (pfParamString) => {
    try {
      const response = await axios.post(`https://sandbox.payfast.co.za/onsite/​process`, pfParamString);
      const identifier = response.data.uuid;
      return identifier;

    } catch (error) {
      console.error("Error generating payment identifier:", error);
      // Handle errors appropriately (e.g., display an error message)
    }
  };


  const dataToString = (dataArray) => {
    // Convert your data array to a string
    let pfParamString = "";
    for (let key in dataArray) {
      if (dataArray.hasOwnProperty(key)) { pfParamString += `${key}=${encodeURIComponent(dataArray[key].trim()).replace(/%20/g, "+")}&`; }
    }
    // Remove last ampersand
    return pfParamString.slice(0, -1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      // **Generate the pfParamString here**
      // const data = {
      //   // Gather all necessary data for payment here
      //   // (merchant_id, merchant_key, customer details, item details, amount, etc.)
      //   merchant_id: "10031748",
      //   merchant_key: "xjflpezet922e",
      //   name_first: { name_first },
      //   name_last: { name_last },
      //   email_address: { email_address },
      //   cell_number: { cell_number },
      //   m_paymentid: { generateUniqueOrderNumber },
      //   amount: (calculateTotalPrice() +
      //     (selectedShippingOption ? selectedShippingOption.price : 0)).toFixed(2),
      //   item_name: { item_title },
      //   custom_int1: { zip },
      //   custom_str1: { streetaddress },
      //   custom_str2: { city },
      //   custom_str3: { province },
      //   signature: { generateSignature },
      //   // passphrase: "bak1c5CaK3i5G00d"
      // };


      const data = {
        // Gather all necessary data for payment here
        // (merchant_id, merchant_key, customer details, item details, amount, etc.)
        merchant_id: "10031748",
        merchant_key: "xjflpezet922e",
        name_first: "Tumelo",
        name_last: "Thinane",
        email_address: { email_address },
        cell_number: "0631120486",
        m_paymentid: "INV0173",
        amount: "500",
        item_name: "Hat",
        custom_int1: 9650,
        custom_str1: { streetaddress },
        custom_str2: { city },
        custom_str3: { province },
        signature: { generateSignature },
        // passphrase: "bak1c5CaK3i5G00d"
      };


      const passPhrase = "Uyak1c5Cdrt3i5G00d";
      const pfParamString = dataToString(data, passPhrase);

      // Generate payment identifier
      const identifier = await generatePaymentIdentifier(pfParamString);
      console.log(data)
      if (identifier) {
        window.payfast_do_onsite_payment({ uuid: identifier }, (result) => {
          if (result === true) {
            // Payment Completed
            // Handle successful payment (e.g., update order status, display success message)
            console.warn("Payment successful")
          } else {
            // Payment Window Closed
            // Handle payment cancellation or closure (e.g., display message, offer retry)
            console.warn("Payment unsuccessful")
          }
        });
      }
    } catch (error) {
      // Handle errors appropriately
    }
  };



  useEffect(() => {
    if (cart && cart.length > 0 && cart[0].title) {
      setItemTitle(cart[0].title);
    }
  }, [cart]);

  return (
    <>
      <form action="https://sandbox.payfast.co.za​/eng/process" method="post" onSubmit={() => {
          saveOrderToDatabase();
        }}>

        <CheckoutProgression currentStep={currentStep} />

        <div class="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
          <div class="px-4 pt-8">
            <OrderSummary
              cart={cart}
              calculateTotalPrice={calculateTotalPrice}
            />

            <ShippingOptions
              onShippingOptionSelect={handleShippingOptionSelect}

            />
          </div>

          <ShippingInfo
            name_first={name_first}
            name_last={name_last}
            email_address={email_address}
            cell_number={cell_number}
            city={city}
            streetaddress={streetaddress}
            zip={zip}
            province={province}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            setCity={setCity}
            setProvince={setProvince}
            setZip={setZip}
            setStreetAddress={setStreetAddress}
            setCellNumber={setCellNumber}
            calculateTotalPrice={calculateTotalPrice}
          />
        </div>

        <input type="hidden" name="merchant_id" value="10031748" />
        <input type="hidden" name="merchant_key" value="xjflpezet922e" />
        <input
          type="hidden"
          name="return_url"
          value="https://euphorea.co.za/checkoutsuccess"
        />
        <input
          type="hidden"
          name="cancel_url"
          value="https://euphorea.co.za/checkoutcancel"
        />
        <input
          type="hidden"
          name="notify_url"
          value="https://euphorea.co.za/checkoutnotify"
        />

        {/* Customer details */}
        <input type="hidden" name="name_first" value={name_first} />
        <input type="hidden" name="name_last" value={name_last} />
        <input type="hidden" name="email_address" value={email_address} />
        <input type="hidden" name="cell_number" value={cell_number} />
        <input
          type="hidden"
          name="custom_str1"
          value={streetaddress}
        />
        <input
          type="hidden"
          name="custom_str2"
          value={city}
        />
        <input
          type="hidden"
          name="custom_str3"
          value={province}
        />
        <input
          type="hidden"
          name="custom_int1"
          value={zip}
        />
        {/* Transaction details */}
        <input
          type="hidden"
          name="m_payment_id"
          value={generateUniqueOrderNumber()}
        />
        <input
          type="hidden"
          name="amount"
          value={(
            calculateTotalPrice() +
            (selectedShippingOption ? selectedShippingOption.price : 0)
          ).toFixed(2)}
        />

        <input type="hidden" name="item_name" value={item_title} />

        {/* Transaction options*/}
        <input type="hidden" name="email_confirmation" value="1" />
        <input
          type="hidden"
          name="confirmation_address"
          value={email_address}
        />

        {/* Payment methods */}
        {/* <input type="hidden" name="payment_method" value="cc" /> */}
        <input type="hidden" name="passphrase" value="Uyak1c5Cdrt3i5G00d" />
        <input type="hidden" name="signature" value={generateSignature} />
      </form>

    </>
  );
}

export default Checkout;
