import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../Database/Firebase";
import { doc } from "firebase/firestore";
import axios from "axios";

const ShippingInfo = ({
  name_first,
  name_last,
  email_address,
  cell_number,
  city,
  streetaddress,
  zip,
  province,
  setFirstName,
  setLastName,
  setEmail,
  setCellNumber,
  setCity,
  setStreetAddress,
  setZip,
  setProvince,
  calculateTotalPrice,
  onShippingOptionSelect
}) => {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState(null);

  const [selectedShippingOption, setSelectedShippingOption] = useState(null);

  const handleShippingOptionSelect = (option) => {
    setSelectedShippingOption(option);
  };

  
  useEffect(() => {
    // Function to get user data from Firestore
    const getUserData = async () => {
      if (user) {
        try {
          const userDoc = doc(db, "users", user.email, "UserDetails");

          if (userDoc.exists) {
            const data = userDoc.data();
            setUserData(data);
          } else {
            console.log("User document not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    getUserData();
  }, [user]);

  useEffect(() => {
    // Set user data in the state when available
    if (userData) {
      setFirstName(userData.name_first || "");
      setLastName(userData.name_last || "");
      setEmail(userData.email || "");
      setCellNumber(userData.cell_number || "");
      setCity(userData.city || "");
      setStreetAddress(userData.streetaddress || "");
      setZip(userData.zip || "");
      setProvince(userData.province || "");
    }
  }, [
    userData,
    setFirstName,
    setLastName,
    setEmail,
    setCellNumber,
    setCity,
    setStreetAddress,
    setZip,
    setProvince
  ]);

  return (
    <>
      <div class="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
        <p class="text-xl font-medium">Payment Details</p>
        <p class="text-gray-400">
          Complete your order by providing your payment details.
        </p>
        <div class="">
          <label for="firstname" class="mt-4 mb-2 block text-sm font-medium">
            First Name
          </label>
          <div class="relative">
            <input
              type="text"
              id="firstname"
              name="name_first"
              class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="First Name"
              value={name_first}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </div>
          </div>

          <label for="lastname" class="mt-4 mb-2 block text-sm font-medium">
            Last Name
          </label>
          <div class="relative">
            <input
              type="text"
              id="lastname"
              name="name_last"
              class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="Last Name"
              value={name_last}
              onChange={(e) => setLastName(e.target.value)}
            />
            <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </div>
          </div>

          <label for="email" class="mt-4 mb-2 block text-sm font-medium">
            Email
          </label>
          <div class="relative">
            <input
              type="email"
              id="email"
              name="email"
              class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="user@example.com"
              value={email_address}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </div>
          </div>

          <label for="cellphone" class="mt-4 mb-2 block text-sm font-medium">
            Cellphone
          </label>
          <div class="relative">
            <input
              type="number"
              id="cell_number"
              name="cell_number"
              class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="Cellphone number"
              value={cell_number}
              onChange={(e) => setCellNumber(e.target.value)}
            />
            <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </div>
          </div>

          <label for="cellphone" class="mt-4 mb-2 block text-sm font-medium">
            Street Address
          </label>
          <div class="relative">
            <input
              type="text"
              id="street_address"
              name="street_address"
              class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="Street Address"
              value={streetaddress}
              onChange={(e) => setStreetAddress(e.target.value)}
            />
            <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </div>
          </div>

          <label for="cellphone" class="mt-4 mb-2 block text-sm font-medium">
            City
          </label>
          <div class="relative">
            <input
              type="text"
              id="city"
              name="city"
              class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </div>
          </div>

          <label for="zip" class="mt-4 mb-2 block text-sm font-medium">
            Zipcode
          </label>
          <div class="relative">
            <input
              type="number"
              id="zip"
              name="zip"
              class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="ZIP"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
            <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </div>
          </div>

          <label
            for="billing-address"
            class="mt-4 mb-2 block text-sm font-medium"
          >
            Province
          </label>
          <div class="flex flex-col sm:flex-row">
            <div class="relative ">
              {/* <input
                type="text"
                id="billing-address"
                name="billing-address"
                class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Street Address"
              /> */}
              {/* <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                <img
                  class="h-4 w-4 object-contain"
                  src="https://flagpack.xyz/_nuxt/4c829b6c0131de7162790d2f897a90fd.svg"
                  alt=""
                />
              </div> */}
            </div>

            <select
              type="text"
              name="billing-state"
              className=" w-52 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              value={province} // Assuming city is the selected province
              onChange={(e) => setProvince(e.target.value)}
            >
              <option value="" disabled>
                Select Province
              </option>
              {[
                "Eastern Cape",
                "Free State",
                "Gauteng",
                "KwaZulu-Natal",
                "Limpopo",
                "Mpumalanga",
                "North West",
                "Northern Cape",
                "Western Cape",
              ].map((province) => (
                <option key={province} value={province}>
                  {province}
                </option>
              ))}
            </select>

            {/* <input
              type="text"
              name="street"
              class="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="Street Address"
              value={streetaddress}
              onChange={(e) => setStreetAddress(e.target.value)}
            />
            <input
              type="text"
              name="city"
              class="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <input
              type="text"
              name="billing-zip"
              class="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="ZIP"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            /> */}
          </div>

          <div class="mt-6 border-t border-b py-2">
            <div class="flex items-center justify-between">
              <p class="text-sm font-medium text-gray-900">Subtotal</p>
              <p class="font-semibold text-gray-900">
                {" "}
                R{calculateTotalPrice().toFixed(2)}
              </p>
            </div>
            <div class="flex items-center justify-between">
              <p class="text-sm font-medium text-gray-900">Shipping</p>

              <p class="font-semibold text-gray-900">R{(
                (selectedShippingOption ? selectedShippingOption.price : 60)
              ).toFixed(2)}</p>

            </div>
          </div>
          <div class="mt-6 flex items-center justify-between">
            <p class="text-sm font-medium text-gray-900">Total</p>
            <p class="text-2xl font-semibold text-gray-900">
              R{(calculateTotalPrice() + selectedShippingOption).toFixed(2)}
            </p>
          </div>
        </div>
        
        <button
          class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
          type="submit"
          value="Pay Now"
        >
          Place Order
        </button>
      </div>
    </>
  );
};

export default ShippingInfo;
