import { useState, useEffect, useRef } from "react";
import SingleProduct from "../Components/Store/SingleProduct";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
// import { doc, addDoc, updateDoc, arrayUnion, setDoc } from "firebase/firestore";
import { db } from "../Database/Firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import productsData from "../Database/data/products";

import NavBar from "../Components/NavBar";

const DummyData = [
  {
      id: 1,
      title: "Euphorea Shirt",
      price: 99.00,
      discountedPrice: 79.00,
      image: "https://images.squarespace-cdn.com/content/v1/62ec1fc995263d65f21175de/1673911291595-MFUHQT5N9BBM5FBEYXVJ/germanchocolate17.jpg",
      sale: true,
      category: "shirts",
      popular: true,
      brand: "Euphorea"
  },
  {
      id: 2,
      title: "Euphorea Hoodie",
      price: 150.00,
      discountedPrice: 100.00,
      image: "https://gardenandgun.com/wp-content/uploads/2020/07/GG0420_Cover_04-promo-1600x840.png",
      sale: true,
      category: "hoodies",
      popular: true,
      brand: "Euphorea"
  },
];

const Store = () => {

  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [filterProducts, setFilterProducts] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [catPath, setCatPath] = useState("all categories");
  const para = useRef(null);

  const handleAddToCart = (newProduct) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.id === newProduct.id);
  
      if (existingItem) {
        // If the item exists, update its quantity
        return prevCart.map((item) =>
          item.id === existingItem.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        // If the item doesn't exist, add it to the cart
        return [...prevCart, { ...newProduct, quantity: 1 }];
      }
    });
  
    // Save the updated cart to local storage
    localStorage.setItem('cart', JSON.stringify([...cart, { ...newProduct, quantity: 1 }]));
  
    console.log("Item added to cart:", newProduct);
    toast.success("Item has been added to the cart.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const categories = ["shirts", "accessories", "headwear", "hoodies", "mugs"];

  useEffect(() => {
    const getData = async () => {
      try {
        // setIsLoading(true);
        const querySnapshot = await getDocs(collection(db, "Store"));
        const productsData = querySnapshot.docs.map((doc) => doc.data());
        setProducts(productsData);
        setFilterProducts(productsData);

        // setProducts(DummyData);
        // setFilterProducts(DummyData);

        console.log("fetched products: ", productsData);
      } catch (error) {
        // setIsLoading(false);
        console.error("Error fetching products: ", error);
      }
    };
    getData();
  }, []);

  if (err)
    return (
      <p className="h-screen flex flex-col justify-center items-center text-2xl">
        <span>{err}</span>
        <Link to="/product" className="text-lg text-gray-500 font-semibold">
          &larr;Refresh page
        </Link>
      </p>
    );

  return (
    <div>
      <NavBar
        onAddToCart={() => setIsCartOpen(!isCartOpen)}
        cartCount={cart.length}
        cartItems={cart}
      />
      <ToastContainer />
      <div className="container mx-auto pb-20 mt-24">
        <h2 className="text-center text-3xl py-10">All Products</h2>
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div className="w-full lg:w-[20%] bg-gray-50 flex flex-col gap-3 px-3 pt-2">
            <h3
              className="select-none cursor-pointer flex justify-between"
              onClick={() => {
                setFilterProducts(products);
                setCatPath("all categories");
              }}
            >
              <span className="font-semibold">All Categories</span>
              <span>{`(${products.length})`}</span>
            </h3>

            {categories.map((cat, i) => (
              <p
                ref={para}
                className="select-none cursor-pointer capitalize font-semibold"
                key={i}
                onClick={() => {
                  const filters = products.filter(
                    (product) => product.category === cat
                  );
                  setFilterProducts(filters);
                  setCatPath(categories[i]);
                }}
              >
                <span>{cat}</span>
              </p>
            ))}
          </div>
          <div className="w-full lg:w-[75%]">
            <p className="text-gray-500 pb-4">
              {<Link to="/">Home </Link>}/
              <span className="text-sky-400 px-1">{catPath}</span>
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
              {filterProducts &&
                filterProducts.map((product) => (
                  <SingleProduct
                    key={product.id}
                    product={product}
                    onAddToCart={handleAddToCart}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;